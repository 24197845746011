import { Stars } from '@c/dashboard/pages/RatingsPage';
import { ShieldCheckIcon, ShieldStarIcon } from '@c/icons';
import Avatar from '@ui/Avatar';
import SafeImage from '@ui/SafeImage';
import { logEvent } from '@util/analytics';
import { ProductDocument } from '@models/product';
import { PublicUserDocument } from '@models/user';
import useListingCount from '@util/hooks/useListingCount';
import { StatusLabel, useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { useChat } from 'context/ChatContext';
import Link from 'next/link';

const SellerCard = ({
  sellerDoc,
  product,
  uid,
}: {
  sellerDoc: PublicUserDocument;
  product: ProductDocument;
  uid?: string;
}) => {
  const { status } = useOnlineStatus(sellerDoc?.uid);
  const listingsCount = useListingCount(sellerDoc?.uid);
  if (!sellerDoc) return null;
  const sold = sellerDoc.num_sold ?? 0;
  const refunded = sellerDoc.num_refunded ?? 0;
  const successRate = sold ? ((sold - refunded) / sold) * 100 : 0;
  const isDirtBike = product?.category === 'Dirt Bikes';
  return (
    <div className="flex w-full  flex-col">
      <span className="mb-4 font-semibold">Meet the seller</span>
      <div
        className={`flex flex-col justify-between gap-[1.6rem] ${
          isDirtBike ? '' : 'sm:flex-row'
        }`}
      >
        <div className="flex w-full  items-center gap-[1.6rem] whitespace-nowrap">
          <Link
            href={`/profile/${sellerDoc?.username_slug}`}
            className="hidden rounded-full sm:inline"
          >
            <Avatar size="mlarge" user={sellerDoc} showBadge />
          </Link>
          <div className="flex grow flex-col gap-[0.8rem] @container sm:gap-0">
            <div className="flex w-full items-center justify-between gap-[0.8rem]">
              <div>
                <div className="flex  items-center gap-[0.8rem]">
                  <Link
                    href={`/profile/${sellerDoc?.username_slug}`}
                    className="rounded-full  sm:hidden"
                  >
                    <Avatar size="small" user={sellerDoc} showBadge />
                  </Link>
                  <Link
                    href={`/profile/${sellerDoc?.username_slug}`}
                    className="sm:text-h4 flex gap-[0.8rem] text-input font-semibold text-brand-secondary"
                  >
                    {sellerDoc.username}

                    <div className="hidden text-black sm:block">
                      <StatusLabel
                        status={status}
                        hide_status={!!sellerDoc.hide_status}
                      />
                    </div>
                  </Link>
                  <div className="my-2 flex items-center gap-[0.4rem] sm:hidden">
                    {sellerDoc.reviews > 0 && (
                      <Stars rating={sellerDoc.rating ?? 0} zoom={0.9} />
                    )}
                  </div>
                </div>

                <div className="my-2 hidden items-center gap-[0.4rem] sm:flex">
                  {sellerDoc.reviews > 0 && (
                    <Stars rating={sellerDoc.rating ?? 0} zoom={0.9} />
                  )}
                  <p className="ml-[0.8rem]">({sellerDoc.reviews} reviews)</p>
                </div>
              </div>
              <div className="mb-[1.6rem] hidden lg:block">
                <MessageSellerButton
                  sellerDoc={sellerDoc}
                  uid={uid}
                  product={product}
                />
              </div>
            </div>
            <div className="sm:hidden">
              <StatusLabel
                status={status}
                hide_status={!!sellerDoc.hide_status}
              />
            </div>

            <div className="mb-4 flex items-center gap-[0.4rem] text-brand-light-black">
              <span className="font-semibold">{listingsCount}</span>
              items listed
              <div className="mx-[0.8rem] h-[2rem] w-px bg-brand-lighter-gray" />
              <span className="font-semibold">{sellerDoc?.num_sold ?? 0}</span>
              sales
            </div>

            <div className="@2:flex-row flex min-w-[57rem] flex-col gap-[1.6rem]">
              <div className="flex flex-col justify-start gap-[1.6rem] lg:flex-row lg:items-center ">
                {sellerDoc?.seller_type === 'privateer' && (
                  <div className="flex items-center gap-[0.4rem]">
                    <div className="text-brand-secondary">
                      <SafeImage
                        src={'https://cdn.mxlocker.com/assets/privateer.svg'}
                        width={24}
                        height={24}
                        alt={'privateer logo'}
                      />
                    </div>
                    <span>Privateer</span>
                  </div>
                )}
                {sellerDoc?.seller_type === 'dealer' && (
                  <div className="flex items-center gap-[0.4rem]">
                    <div className="text-brand-secondary">
                      <SafeImage
                        src={'https://cdn.mxlocker.com/assets/box hand 3.svg'}
                        width={24}
                        height={24}
                        alt={'dealer logo'}
                      />
                    </div>
                    <span>Local Dealer</span>
                  </div>
                )}
                {sellerDoc.is_verified && (
                  <div className="flex items-center gap-[0.4rem]">
                    <ShieldCheckIcon />
                    <span>Profile Verified</span>
                  </div>
                )}
                {sellerDoc.top_seller && (
                  <div className="flex items-center gap-[0.4rem]">
                    <div className="text-brand-secondary">
                      <ShieldStarIcon />
                    </div>
                    <span>Top Seller</span>
                  </div>
                )}
                {sold > 0 && !isDirtBike && (
                  <div className="flex flex-col">
                    <span>{successRate.toFixed(2)}% Success Rate</span>
                    <progress
                      className="seller-progress h-[0.4rem] lg:w-full"
                      value={successRate}
                      max="100"
                    />
                  </div>
                )}
              </div>
              <div className="lg:hidden">
                <MessageSellerButton
                  sellerDoc={sellerDoc}
                  uid={uid}
                  product={product}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function MessageSellerButton({
  sellerDoc,
  uid,
  product,
}: {
  sellerDoc: PublicUserDocument;
  product: ProductDocument;
  uid?: string;
}) {
  const { openChatModalWithRecipientAndProductId } = useChat();
  const isDirtBike = product.category === 'Dirt Bikes';
  return (
    <div className={`${isDirtBike ? 'w-full' : ''} font-semibold`}>
      {(!uid || uid !== sellerDoc.uid) && (
        <button
          onClick={() => {
            logEvent('generate_lead', {
              value: product.price,
              currency: 'USD',
            });
            openChatModalWithRecipientAndProductId({
              recipientUid: sellerDoc.uid,
              productId: product.id,
            });
          }}
          className="color flex items-center gap-[0.8rem] rounded-brand border-[1px] bg-gray-200 px-[1rem] py-[0.6rem] font-semibold tracking-wide text-gray-800"
        >
          Message seller
        </button>
      )}
    </div>
  );
}

export default SellerCard;
