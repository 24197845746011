'use client';
import { DocumentIcon, LikeShapesIcon, StarIcon } from '@c/icons';
import { ReviewDocument } from '@models/review';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import ButtonToggle from '@ui/ButtonToggle';
import { Divider } from '@ui/Divider';
import Input from '@ui/Input';
import Select from '@ui/Select';
import Spinner from '@ui/Spinner';
import { getOrderById } from '@util/firestore/orders';
import { getReviewsByUid } from '@util/firestore/reviews';
import { getPublicUserDoc } from '@util/firestore/users';
import { ButtonToggleType } from '@util/types/buttontoggle';
import { useAuth } from 'context/AuthContext';
import { PublicUserDocument, UserDocument } from 'models/user';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import EmptyState from '../EmptyState';
import SafeImage from '@ui/SafeImage';

const SellerReviewCard = ({ review }: { review: ReviewDocument }) => {
  const { data: user } = useQuery({
    queryKey: ['publicUser', review.reviewer_id],
    queryFn: () => getPublicUserDoc({ uid: review.reviewer_id }),
    enabled: !!review.reviewer_id,
  });

  const { data: order } = useQuery({
    queryKey: ['order', review.order_id],
    queryFn: () => getOrderById(review.order_id),
    enabled: !!review.order_id,
  });

  return (
    <div className="flex flex-col justify-center gap-[1.6rem] p-[1.6rem] lg:rounded-2xl lg:border-[1px]">
      <div className="flex items-center justify-between gap-[0.8rem]">
        <Link
          className="flex items-center gap-[0.4rem]"
          href={`/dashboard/orders/${review.order_id}`}
        >
          <DocumentIcon />
          {order ? (
            <span className="font-semibold"># MX{order.order_num}</span>
          ) : (
            <span className="h-[1.2rem] w-[1.2rem] animate-pulse rounded-full bg-brand-gray" />
          )}
        </Link>
        <span className="text-[1.3rem] text-brand-dark-gray">
          {review.created &&
            new Date(review.created).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
        </span>
      </div>
      <div className="flex flex-col gap-[1.6rem] lg:flex-row lg:justify-between">
        <div className="flex flex-col gap-[1.6rem]">
          <div className="flex gap-[1.6rem]">
            <Avatar user={user} />
            <div className="flex flex-col gap-[0.4rem]">
              <span>{user?.username}</span>
              <Stars rating={review.rating} />
            </div>
          </div>
        </div>
        {/* <div className="flex gap-[2.4rem]">
          <div className="flex flex-col justify-center gap-[0.8rem] lg:flex-row lg:items-center">
            <span className="text-[1.3rem]">Seller Communication</span>
            <ReviewChip rating={review.communication} variant="communication" />
          </div>
          <div className="h-[4.8rem] w-[1px] bg-[#D9D9D9]" />
          <div className="flex flex-col justify-center gap-[0.8rem] lg:flex-row lg:items-center">
            <span className="text-[1.3rem]">Order Delivery Time</span>
            <ReviewChip rating={review.delivery_time} variant="shipping" />
          </div>
        </div> */}
      </div>
      {review.products && (
        <div className="flex flex-col gap-[0.8rem] lg:pl-[7.5rem]">
          <span className="line-clamp-3 font-semibold">
            {review.products?.map((product) => product.title).join(', ')}
          </span>
          <span className="text-brand-lightest-black">{review.comment}</span>
        </div>
      )}
      {review.images?.map((image) => (
        <SafeImage
          key={image.thumb}
          src={image.thumb}
          width={100}
          height={100}
          alt={review.comment}
        />
      ))}
    </div>
  );
};

export const Stars = ({
  rating = 0,
  zoom = 1,
}: {
  rating: number;
  zoom?: number;
}) => (
  <div
    className="flex gap-[0.4rem]"
    style={{
      zoom: zoom,
    }}
  >
    {[...Array(Math.ceil(rating))].map((_, index) => (
      <StarIcon key={index} />
    ))}
    {[...Array(Math.max(5 - Math.ceil(rating), 0))].map((_, index) => (
      <StarIcon key={index} fill="#808080" />
    ))}
    ({rating.toFixed(1)})
  </div>
);

const ReviewsSection = ({ reviews }: { reviews: ReviewDocument[] }) => {
  return (
    <div className="flex flex-col gap-[1.6em]">
      <Divider />
      {reviews.map((review) => (
        <SellerReviewCard key={review.order_id} review={review} />
      ))}
      {/* TODO: Pagination */}
      {/* <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center py-[1.6rem] gap-[1.6rem] w-full">
        <span className="whitespace-nowrap grow lg:w-full">Show 10 of 40</span>
        <Paginator totalPages={9} currentPage={1} />
      </div> */}
    </div>
  );
};

const ShopRatings = ({ reviews }: { reviews: ReviewDocument[] }) => {
  const [filters, setFilters] = useState({
    sentiment: 'all',
    date: 'all',
    search: '',
  });

  // const { userDoc } = useAuth();

  const buttonToggleOptions: ButtonToggleType[] = [
    {
      label: 'All',
      key: 'all',
      selected: true,
      onClick: () => setFilters({ ...filters, sentiment: 'all' }),
    },
    {
      label: 'Positive',
      key: 'positive',
      onClick: () => setFilters({ ...filters, sentiment: 'positive' }),
    },
    {
      label: 'Negative',
      key: 'negative',
      onClick: () => setFilters({ ...filters, sentiment: 'negative' }),
    },
  ];

  const filteredReviews = useMemo(() => {
    let filteredReviews = reviews;
    if (filters.date === 'all') {
      filteredReviews = reviews;
    } else if (filters.date === 'last-3-months') {
      filteredReviews = reviews.filter((review) => {
        const date = new Date(review.created!);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const diffInDays = diff / (1000 * 3600 * 24);
        return diffInDays <= 90;
      });
    } else if (filters.date === 'last-6-months') {
      filteredReviews = reviews.filter((review) => {
        const date = new Date(review.created!);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const diffInDays = diff / (1000 * 3600 * 24);
        return diffInDays <= 180;
      });
    } else if (filters.date === 'last-12-months') {
      filteredReviews = reviews.filter((review) => {
        const date = new Date(review.created!);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const diffInDays = diff / (1000 * 3600 * 24);
        return diffInDays <= 365;
      });
    }
    if (filters.sentiment === 'all') {
      filteredReviews = filteredReviews;
    } else if (filters.sentiment === 'positive') {
      filteredReviews = filteredReviews.filter((review) => review.rating >= 3);
    } else if (filters.sentiment === 'negative') {
      filteredReviews = filteredReviews.filter((review) => review.rating < 3);
    }
    if (filters.search) {
      //  Just a basic search for now, we can improve this later
      filteredReviews = filteredReviews.filter((review) => {
        return review.products?.some((product) =>
          product.title.toLowerCase().includes(filters.search.toLowerCase())
        );
      });
    }
    return filteredReviews;
  }, [filters, reviews]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-[1.6rem] px-[1.6rem] py-[2.4rem] lg:flex-row lg:justify-between">
        <h1 className="text-[2.4rem] font-semibold leading-[3.2rem] text-brand-lightest-black lg:grow">
          Shop Reviews
        </h1>
        {!!reviews.length && (
          <Input
            placeholder="Search by product name ..."
            type="search"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        )}
      </div>
      <Divider />
      {!!reviews.length && (
        <div className="flex w-full flex-col gap-[1.6rem] p-[1.6rem] lg:flex-row lg:justify-between">
          <div className="flex w-1/4 flex-col  items-center gap-[0.8rem] lg:flex-row">
            <h2 className="font-semibold">Filter</h2>
            <Select
              allowEmpty={false}
              options={[
                {
                  label: 'Last 3 months',
                  value: 'last-3-months',
                  id: 'last-3-months',
                },
                {
                  label: 'Last 6 months',
                  value: 'last-6-months',
                  id: 'last-6-months',
                },
                {
                  label: 'Last 12 months',
                  value: 'last-12-months',
                  id: 'last-12-months',
                },
                {
                  label: 'All time',
                  value: 'all',
                  id: 'all',
                },
              ]}
              value={filters.date}
              onChange={(e) => setFilters({ ...filters, date: e.value })}
              placeholder="Last 3 months"
            />
          </div>
          <ButtonToggle buttons={buttonToggleOptions} />
        </div>
      )}
      <Divider />
      <ReviewsSection reviews={filteredReviews} />
    </div>
  );
};

export const ReviewSummary = ({
  userDoc,
  positiveReviews,
  negativeReviews,
}: {
  userDoc: UserDocument | PublicUserDocument | null;
  positiveReviews: number;
  negativeReviews: number;
}) => {
  return (
    <div className="mb-8 flex flex-col justify-center gap-[1.6rem] p-[1.6rem] lg:flex-row lg:items-center lg:justify-between lg:gap-[5rem] lg:rounded-2xl lg:border-[1px]">
      <div className="flex flex-col gap-[1.6rem]">
        <h2 className="text-[1.8rem] font-semibold text-brand-lightest-black">
          Review Summary
        </h2>
        <div className="flex items-center gap-[1.2rem]">
          <span className="text-[3.2rem] font-semibold text-brand-lightest-black">
            {(userDoc?.rating ?? 0).toFixed(1)}
          </span>
          <div className="flex flex-col justify-center gap-[0.4rem]">
            <Stars rating={userDoc?.rating ?? 0} />
            <span className="text-brand-lightest-black">
              ({userDoc?.reviews ?? 0} total reviews)
            </span>
          </div>
        </div>
      </div>
      <div className="flex grow flex-col gap-[1.6rem]">
        <div className="h-[1.2rem] w-full bg-[#808080]">
          <div
            className="h-[1.2rem] bg-brand-green "
            style={{
              width: `${Math.floor(
                (positiveReviews / (userDoc?.reviews ?? 0)) * 100
              )}%`,
            }}
          ></div>
        </div>
        <div className="flex w-full items-center gap-[4rem]">
          <div className="flex items-center gap-[0.8rem]">
            <div className="h-[1.2rem] w-[1.2rem] rounded-full bg-brand-green"></div>
            <span>Positive</span>
            <span className="font-semibold">{positiveReviews}</span>
          </div>
          <div className="flex items-center gap-[0.8rem]">
            <div className="h-[1.2rem] w-[1.2rem] rounded-full bg-brand-gray"></div>
            <span>Negative</span>
            <span className="font-semibold">{negativeReviews}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const RatingsPage = () => {
  const { userDoc } = useAuth();
  const { data: reviews, isLoading } = useQuery({
    queryKey: ['reviews'],
    queryFn: () => getReviewsByUid(userDoc?.uid ?? ''),
    enabled: !!userDoc,
  });

  if (isLoading)
    return (
      <div className="mx-auto h-[10rem] w-[10rem]">
        <Spinner />
      </div>
    );

  return !!userDoc?.rating ? (
    <ShopRatings reviews={reviews ?? []} />
  ) : (
    <EmptyState
      icon={<LikeShapesIcon />}
      title={'No Ratings'}
      description={"Your shop doesn't have any ratings yet."}
    />
  );
};

export default RatingsPage;
