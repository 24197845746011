import AcceptedCards from '@c/icons/acceptedCards';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import { formatCurrency, getCountryLabelFromCode } from '@util/index';
import { ProductDocument } from '@models/product';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

const EstimateShippingModal = dynamic(
  () => import('../modals/EstimateShippingModal')
);

const ProductShippingAndPayments = ({
  product,
  shipping,
  setShipping,
  hidePayments,
}: {
  product: ProductDocument;
  shipping?: {
    code: string;
    cost: number;
    name: string;
    estimated?: boolean;
  };
  setShipping?: (s: {
    code: string;
    cost: number;
    name: string;
    estimated?: boolean;
  }) => void;
  hidePayments?: boolean;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState<{
    code: string;
    cost: number;
    name: string;
    estimated?: boolean;
  } | null>(
    product.shipping_costs?.find((s) => s.code === 'US') ??
      product.shipping_costs?.[0] ??
      null
  );
  return (
    <>
      <h3 className="text-[1.5rem] font-semibold">Shipping & Payments</h3>
      {product.is_flat_rate && shipping && !shipping.estimated && (
        <div className="flex h-[4.4rem] w-min items-center gap-[1.2rem] whitespace-nowrap rounded-[0.4rem] bg-[#F1F1F1] p-[1.2rem]">
          <DropdownMenu
            label={`+ ${formatCurrency(selectedShipping?.cost ?? 0)} - ${
              selectedShipping?.name
            }`}
            menuItems={
              product.shipping_costs?.map((s) => {
                return {
                  label: `+ ${formatCurrency(s.cost)} - ${s.name}`,
                  onClick() {
                    setSelectedShipping(s);
                    if (setShipping) {
                      setShipping(s);
                    }
                  },
                };
              }) ?? []
            }
          />
        </div>
      )}

      {product.is_flat_rate === false && (
        <div className="underline">
          <Button
            text="Estimate Shipping"
            type="text"
            noXPadding
            onClick={() => setOpenModal(true)}
          />
        </div>
      )}

      <h3>Ships From {getCountryLabelFromCode(product.country_code)}</h3>
      {!hidePayments && (
        <>
          <AcceptedCards />
          <p className="text-[1.2rem] text-gray-500">
            MX Locker keeps your payment information secure.<br></br>MX Locker
            sellers never receive your credit card information.
          </p>
        </>
      )}
      {openModal && (
        <EstimateShippingModal
          isOpen={openModal}
          product_id={product.id}
          dismiss={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default ProductShippingAndPayments;
