'use client';
import {
  ChevronLeftIcon,
  CloseIcon,
  CurationsIcon,
  PaperclipIcon,
  SendIcon,
} from '@c/icons';
import { LogoWithoutText } from '@c/icons/logoWithoutText';
import ProductViewerModal from '@c/modals/ProductViewerModal';
import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import {
  flagChat,
  getChatById,
  hideChat,
  markAsUnread,
  uploadChatImage,
} from '@util/firestore/messages';
import { getOffer } from '@util/firestore/offers';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { StatusLabel, useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCdnImageUrls } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useToastContext } from 'context/ToastContext';
import { onSnapshot } from 'firebase/firestore';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MessageBubble from './MessageBubble';

export interface MessageWindowProps {
  goBack: () => void;
  chat?: ChatDocument;
  previewSlot?: React.ReactNode;
}

export default function MessageWindow({
  goBack,
  chat: initialChat,
  previewSlot,
}: MessageWindowProps) {
  const [chat, setChat] = useState<ChatDocument | undefined>(initialChat);
  const [loadingButton, setLoadingButton] = useState('');
  const inputId = useRef<string>(Math.random().toString(36).substring(7));
  const { sendMessage } = useChat();
  const queryClient = useQueryClient();
  const [message, setMessage] = React.useState<string>('');
  const { userDoc } = useAuth();
  const messageWindowRef = React.useRef<HTMLDivElement>(null);
  const [fileList, setFileList] = React.useState<FileList | undefined>();
  const [fallBackImages, setFallBackImages] = React.useState(
    new Map<string, string[]>()
  );
  const [fallbackByURLImages, setFallbackByURLImages] = React.useState<
    Map<string, string>
  >(new Map());
  const [loadingImage, setLoadingImage] = React.useState(false);
  const [imageModal, setImageModal] = React.useState<{
    isOpen: boolean;
    index: number;
    images: { full: string; thumb: string }[];
  }>({ isOpen: false, index: 0, images: [] });
  const { data: offer } = useQuery({
    queryKey: ['offer', chat?.offer_id ?? ''],
    queryFn: () => getOffer(chat?.offer_id ?? ''),
    enabled: !!chat?.offer_id,
  });
  const { data: product } = useQuery({
    queryKey: ['product', chat?.product_id ?? ''],
    queryFn: () => getProductById(chat?.product_id ?? ''),
    enabled: !!offer?.product_id,
  });

  const recipient = !chat
    ? undefined
    : chat.buyer_id === userDoc?.uid
    ? chat.seller_id
    : chat.buyer_id;
  const { data: recipientDoc } = useQuery({
    queryKey: ['publicUser', recipient],
    queryFn: () => getPublicUserDoc({ uid: recipient! }),
    enabled: !!recipient,
  });

  const handleSubmit = async () => {
    if (!userDoc || !chat?.id) return;
    const messageDoc = {
      uid: userDoc.uid,
      content: message,
      created_at: new Date().getTime(),
    } as ChatMessageDocument;
    if (messageDoc.content) {
      sendMessage(messageDoc, chat.id, product ?? undefined);
    }
    // Send another message with the image url
    const files = Array.from(fileList ?? []);
    const dataUrls: string[] = [];
    if (files?.length) {
      files.forEach((file) => {
        const messageDocCopy = { ...messageDoc };
        uploadChatImage(file, userDoc.uid, chat.id).then((res) => {
          const { path, download_url } = res;
          const { full, thumb } = getCdnImageUrls(path);
          messageDocCopy.thumbnail = thumb;
          messageDocCopy.content = full;
          messageDocCopy.is_img = true;
          messageDocCopy.download_url = download_url;
          sendMessage(messageDocCopy, chat.id!);
          setFallbackByURLImages((prev) => new Map(prev).set(thumb, dataUrl));
          const dataUrl = URL.createObjectURL(file);
          dataUrls.push(dataUrl);
        });
        setLoadingImage(true);
      });
    }
    setFallBackImages((prev) => new Map(prev).set(chat.id!, dataUrls));

    setMessage('');
    setFileList(undefined);
    // scroll to bottom
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });
  }, []);

  const onImageClick = (src: string) => {
    let index: number | undefined;
    const images: { thumb: string; full: string }[] = [];
    messages
      ?.filter((m) => m.is_img)
      .forEach((msg, i) => {
        if (index === undefined && src === msg.thumbnail) index = i;
        images.push({ thumb: msg.thumbnail ?? msg.content, full: msg.content });
      });
    if (index === undefined) index = 0;
    setImageModal({ images, index, isOpen: true });
  };

  const { status } = useOnlineStatus(recipientDoc?.uid ?? '');

  // subscribe to real time messages
  const [messages, setMessages] = useState<ChatMessageDocument[] | null>(null);
  const chatsQuery = useMemo(() => getChatById(chat?.id ?? ''), [chat]);

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const data = snapshot.data();
      setMessages(data?.messages ?? []);
      setLoadingImage(false);
      setTimeout(() => {
        if (messageWindowRef.current) {
          messageWindowRef.current.scrollTop =
            messageWindowRef.current.scrollHeight;
        }
      });
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  const textInput = useRef<HTMLTextAreaElement | null>(null);

  const autoIncreaseHeight = () => {
    if (textInput.current) {
      textInput.current.style.height = 'auto';
      textInput.current.style.height = `${textInput.current.scrollHeight}px`;
    }
  };
  const { showSuccessToast } = useToastContext();

  if (!userDoc || !chat) return null;

  return (
    <div className="flex h-full w-full flex-col @container">
      <div className="border-b border-brand-lightest-gray shadow-sm">
        <div className="flex justify-between gap-[1.6rem] p-[1.6rem]">
          <div className="flex w-full">
            <Button
              leadingIcon={<ChevronLeftIcon />}
              type="text"
              width="small"
              onClick={goBack}
            />

            <div className="flex w-full flex-col  gap-[1.6rem] ">
              {recipientDoc &&
                recipientDoc.uid !== process.env.NEXT_PUBLIC_SUPPORT_ID && (
                  <Link
                    className="flex items-center"
                    href={`/profile/${recipientDoc.username_slug}`}
                  >
                    <Avatar size="small" user={recipientDoc} showBadge />
                    <div className="ml-4">
                      <h3 className="font-semibold">{recipientDoc.username}</h3>
                      <StatusLabel
                        status={status}
                        hide_status={recipientDoc.hide_status}
                        showLastLogin={userDoc?.roles?.support}
                        recipientDocUid={recipientDoc.uid}
                      />
                    </div>
                  </Link>
                )}
              {recipientDoc &&
                recipientDoc.uid === process.env.NEXT_PUBLIC_SUPPORT_ID && (
                  <span className="flex flex-col">
                    <h3 className="font-semibold">MX Locker Support</h3>
                    <StatusLabel
                      status={status}
                      hide_status={recipientDoc.hide_status}
                    />
                  </span>
                )}
              {userDoc?.roles?.support && (
                <div className="flex flex-nowrap items-center gap-[1.6rem] overflow-x-scroll pr-[4.8rem] ">
                  {userDoc?.roles?.admin && (
                    <>
                      <Button
                        height="small"
                        type="tertiary"
                        width="small"
                        onClick={() => {
                          window.navigator.clipboard.writeText(
                            recipientDoc?.uid ?? ''
                          );
                          showSuccessToast('Copied to clipboard');
                        }}
                        text="Copy UID"
                      />
                      <Button
                        height="small"
                        type="tertiary"
                        width="small"
                        text="Backend"
                        href={`https://console.firebase.google.com/project/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/firestore/data/~2Fmessages~2F${chat.id}`}
                        target="_blank"
                      />
                    </>
                  )}
                  <Button
                    height="small"
                    type="tertiary"
                    width="small"
                    text={chat.flagged ? 'Unflag' : 'Flag'}
                    onClick={async () => {
                      await flagChat(chat.id!, !chat.flagged);
                      queryClient.invalidateQueries({
                        queryKey: ['adminFlaggedChats'],
                      });
                      setChat({ ...chat, flagged: !chat.flagged });
                    }}
                  />
                  {process.env.NEXT_PUBLIC_SUPPORT_ID && (
                    <Button
                      height="small"
                      type="tertiary"
                      width="small"
                      text={'Mark as Unread'}
                      loading={loadingButton === 'unread'}
                      onClick={async () => {
                        if (!process.env.NEXT_PUBLIC_SUPPORT_ID) return;
                        try {
                          setLoadingButton('unread');
                          await markAsUnread(
                            chat.id,
                            process.env.NEXT_PUBLIC_SUPPORT_ID
                          );
                          showSuccessToast('Marked as unread');
                        } catch (e) {
                          alert('Error marking as unread');
                        } finally {
                          setLoadingButton('');
                        }
                      }}
                    />
                  )}

                  <Button
                    height="small"
                    type="secondary"
                    width="small"
                    text="Hide"
                    onClick={() =>
                      hideChat(chat.id!, userDoc.uid).then(() => {
                        queryClient.invalidateQueries({
                          queryKey: ['adminBuyerChats'],
                        });
                        queryClient.invalidateQueries({
                          queryKey: ['adminSellerChats'],
                        });
                      })
                    }
                  />
                  <Button
                    height="small"
                    type="tertiary"
                    width="small"
                    text="Share"
                    onClick={() =>
                      // copy to clipboard
                      navigator.clipboard
                        .writeText(
                          `${window.location.origin}/dashboard/messages/${chat.id}`
                        )
                        .then(() => {
                          showSuccessToast('Copied to clipboard');
                        })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="block @4xl:hidden">
        {!!previewSlot && (
          <>
            <hr />
            {previewSlot}
          </>
        )}
      </div>
      {/* messages */}
      <div
        ref={messageWindowRef}
        className="relative flex h-full w-full overflow-y-scroll @container"
      >
        <div className="relative flex w-full grow flex-col justify-start gap-[1.6rem] pb-[.6rem] pl-[1.6rem] pr-[.6rem] pt-4">
          {/* <p className="text-brand-gray text-[1.3rem] sticky top-5 self-center">
            20 NOV
          </p> */}
          <div className="mx-auto flex max-w-[100rem] items-center justify-center gap-[1.6rem] rounded-brand bg-gray-100 px-4 py-[1.6rem] text-[1rem] @4xl:w-3/4 @4xl:text-[1.6rem] lg:text-[1.2rem]">
            <div>
              <LogoWithoutText className="h-[2rem] w-[2rem] lg:h-[2.8rem] lg:w-[2.8rem]" />
            </div>
            {product?.category === 'Dirt Bikes'
              ? `MX Locker is not responsible for the sale of dirt bikes. Beware of scammers when selling outside the platform.`
              : ` To protect your payment, always communicate and pay through the MX Locker website or app.`}
          </div>
          <div className="grow">
            {messages?.map((message, i) => (
              <MessageBubble
                chat={chat}
                key={message.created_at + '-' + i}
                chatMessage={message}
                fallbackImg={fallbackByURLImages.get(message.thumbnail ?? '')}
                onImageClick={(src) => onImageClick(src)}
              />
            ))}
            {loadingImage &&
              fallBackImages.get(chat.id!)?.map((dataUrl) => (
                <MessageBubble
                  chat={chat}
                  key="loading-image"
                  chatMessage={{
                    created_at: Date.now(),
                    content: '',
                    thumbnail: dataUrl,
                    is_img: true,
                    uid: userDoc.uid,
                    is_auto: false,
                  }}
                  onImageClick={(src) => onImageClick(src)}
                />
              ))}
            {chat.is_expert &&
              messages?.every((msg) => msg.uid === userDoc.uid) && (
                <span className=" flex w-full grow items-center justify-center gap-4 text-[1.2rem] font-medium text-brand-dark-gray">
                  <CurationsIcon />
                  Experts usually respond within a few hours
                </span>
              )}
          </div>
          {imageModal.isOpen && (
            <ProductViewerModal
              isOpen={imageModal.isOpen}
              dismiss={() => setImageModal({ ...imageModal, isOpen: false })}
              images={imageModal.images}
              initialImage={imageModal.index}
            />
          )}
          {offer?.state === 2 && (
            <span className="py-6 text-center text-zinc-500">
              Offer has ended.{' '}
              {offer.buyer_id === userDoc.uid ? 'Seller' : 'You'} declined the
              offer.
            </span>
          )}
          <div className="flex items-center gap-[1.6rem] border-t border-brand-lightest-gray bg-brand-white p-[1.6rem] lg:sticky lg:bottom-0">
            <textarea
              ref={textInput}
              className={`relative flex grow flex-row items-center justify-between gap-[0.8rem] overflow-hidden rounded-2xl bg-brand-darker-white px-[2rem] leading-[1.2]`}
              value={message}
              onChange={(e) => {
                autoIncreaseHeight();
                setMessage(e.target.value);
              }}
              placeholder={'Write your message here...'}
              onKeyDown={(e) => {
                if (e.key !== 'Enter') return;
                // if shift is pressed, add a new line
                if (e.shiftKey) return;
                e.preventDefault();
                handleSubmit();
              }}
            />
            <input
              id={`file-input-${inputId.current}`}
              type="file"
              accept="image/*"
              className="hidden"
              multiple={true}
              onChange={(e) => {
                if (!e.target.files) return;
                setFileList(e.target.files);
              }}
            />
            {fileList ? (
              <div className="relative" id="preview-list">
                <div className="h-[6.4rem] min-h-[6.4rem] w-[6.4rem] min-w-[6.4rem]">
                  {Array.from(fileList).map((file, index) => (
                    <SafeImage
                      key={file.name}
                      src={URL.createObjectURL(file)}
                      alt="preview"
                      height={64}
                      width={64}
                      className="absolute left-0  top-0 h-[6.4rem] min-h-[6.4rem] w-[6.4rem] min-w-[6.4rem] rounded-2xl object-cover"
                      style={{
                        // rotate slightly based on index
                        transform: `rotate(${
                          index % 2 === 0 ? -index * 10 : index * 10
                        }deg)`,
                        // z index based on index
                        zIndex: 100 - index,
                      }}
                    />
                  ))}
                </div>

                <button
                  className="absolute right-0 top-0 z-[100] flex h-[2.4rem] w-[2.4rem] items-center justify-center rounded-full bg-brand-red text-white"
                  onClick={() => {
                    setFileList(undefined);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  const input = document.querySelector(
                    `#file-input-${inputId.current}`
                  ) as HTMLInputElement;
                  input.click();
                }}
                className="h-[6.4rem]"
              >
                <div className="relative">
                  <PaperclipIcon />
                </div>
              </button>
            )}

            <button
              type="button"
              className="flex aspect-square h-[4.4rem] flex-col items-center justify-center rounded-full border-2 border-brand-secondary bg-brand-secondary text-white transition-colors hover:bg-transparent hover:text-brand-secondary"
              onClick={handleSubmit}
            >
              <SendIcon />
            </button>
          </div>
        </div>
        <div
          className="sticky right-0 top-0 hidden @4xl:block"
          style={{ zoom: 0.8 }}
        >
          {previewSlot}
        </div>
      </div>
    </div>
  );
}
