import SellerCard from '@c/cards/SellerCard';
import { ArrowUpRightIcon } from '@c/icons';
import HeartIconButton from '@c/icons/buttons/HeartButton';
import BaseModal from '@c/modals/BaseModal';
import ProductViewerModal from '@c/modals/ProductViewerModal';
import BuyerActionButtons from '@c/product-details/BuyerActionButtons';
import ProductDescription from '@c/product-details/ProductDescription';
import ProductOverview from '@c/product-details/ProductOverview';
import ProductPrice from '@c/product-details/ProductPrice';
import ProductShippingAndPayments from '@c/product-details/ProductShippingAndPayments';
import ShareOrSellYours from '@c/product-details/ShareOrSellYours';
import { useBuyerActionButtons } from '@features/shop';
import { ProductDocument } from '@models/product';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import ProductCarousel from '@ui/ProductCarousel';
import { getBrandByName } from '@util/firestore/brands/brands.service';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import { getPublicUserDoc } from '@util/firestore/users';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

const QuickViewModal = ({
  product,
  isOpen,
  dismiss,
}: {
  product: ProductDocument;
  isOpen: boolean;
  dismiss: () => void;
}) => {
  const [openProductViewer, setOpenProductViewer] = useState(false);
  return (
    <>
      <BaseModal
        isOpen={isOpen}
        dismiss={dismiss}
        title={
          <div className="flex items-center text-[2.2rem] font-semibold">
            {product.title}
          </div>
        }
      >
        <QuickViewContent
          product={product}
          dismiss={dismiss}
          setOpenProductViewer={setOpenProductViewer}
        />
      </BaseModal>
      <ProductViewerModal
        video={product.video}
        isOpen={openProductViewer}
        dismiss={() => setOpenProductViewer(false)}
        images={product.images}
      />
    </>
  );
};

export function QuickViewContent({
  product,
  setOpenProductViewer,
  dismiss,
}: {
  product: ProductDocument;
  setOpenProductViewer?: (open: boolean) => void;
  dismiss?: () => void;
}) {
  const { userDoc } = useAuth();
  const pathname = usePathname() ?? '';
  const { selectedVariations, errors, setErrors, onSelection } =
    useBuyerActionButtons(product);

  // back up data to show seller info for things like "for you" which is fetched client side
  const { data: sellerDoc } = useQuery({
    queryKey: ['publicUser', product.seller_id],
    queryFn: () => getPublicUserDoc({ uid: product.seller_id }),
  });

  const { data: brand } = useQuery({
    queryKey: ['brand', product.brand],
    queryFn: () => getBrandByName(product.brand),
    enabled: !!product.brand,
  });

  const { filters } = useSearch();
  return (
    <div
      className="flex  gap-[2.4rem] self-stretch"
      style={{
        zoom: 0.75,
      }}
    >
      <div className="relative flex max-w-[50%] flex-col gap-[1.6rem]">
        <div className="absolute right-4 top-4 z-30 cursor-pointer">
          <HeartIconButton
            itemId={product.id}
            initialCount={product.favorite_count ?? 0}
          />
        </div>
        <ProductCarousel
          product={product}
          openProductViewer={() => setOpenProductViewer?.(true)}
          stack
        />
        <ShareOrSellYours product={product} />
        {sellerDoc && (
          <SellerCard
            uid={userDoc?.uid}
            sellerDoc={sellerDoc}
            product={product}
          />
        )}
      </div>
      <div className="flex max-w-[50%] flex-col gap-[1.6rem]">
        <ProductPrice product={product} />
        <ProductShippingAndPayments
          product={product}
          shipping={product.shipping_costs?.[0]}
          // setShipping={setShipping}
          hidePayments
        />
        <ProductOverview product={product} breadcrumbs={[]} brand={brand} />
        <ProductDescription product={product} />
        <BuyerActionButtons
          product={product}
          seller={sellerDoc}
          onSelection={onSelection}
          selectedVariations={selectedVariations}
          errors={errors}
          setErrors={setErrors}
          onAddToCart={() => dismiss?.()}
        />
        <div className="flex w-full justify-center font-medium text-brand-secondary">
          <Button
            type="text"
            text="View More Details"
            trailingIcon={<ArrowUpRightIcon />}
            href={getProductSlug(product)}
            onClick={() => {
              if (pathname.includes('search') && userDoc) {
                trackUserInteraction({
                  uid: userDoc.uid,
                  pid: product.id,
                  interaction: 'search_clicked',
                });
              }
              if (pathname.includes('search') && filters?.term) {
                trackUserSearch({
                  ...filters,
                  product_clicked: product.id,
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default QuickViewModal;
