import { useState, useRef, useEffect } from 'react';

export default function useTextOverflow() {
  const [readingMore, setReadingMore] = useState(false);
  const overflowingText = useRef<HTMLParagraphElement | null>(null);
  const [overflowActive, setOverflowActive] = useState(false);

  useEffect(() => {
    setOverflowActive(checkOverflow(overflowingText.current));
  }, [overflowActive]);

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
      return (
        textContainer.offsetHeight < textContainer.scrollHeight ||
        textContainer.offsetWidth < textContainer.scrollWidth
      );
    return false;
  };

  return { readingMore, setReadingMore, overflowingText, overflowActive };
}
