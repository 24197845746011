import { ChevronDownIcon } from '@c/icons';
import {
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  safePolygon,
} from '@floating-ui/react-dom-interactions';
import {
  DropdownMenuItemType,
  NavMenuItemType,
} from '@util/hooks/useMenuItems';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';

type Props = {
  label?: string;
  icon?: React.ReactNode;
  menuItems: DropdownMenuItemType[] | NavMenuItemType[];
  layout?: 'grid' | 'list';
  openOnHover?: boolean;
  hideArrow?: boolean;
  className?: string;
};

const DropdownMenu = ({
  label,
  icon,
  menuItems,
  layout = 'list',
  openOnHover = false,
  hideArrow = false,
  className,
}: Props) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
  });

  const { getReferenceProps } = useInteractions([
    useClick(context, { toggle: true }),
    useHover(context, { enabled: openOnHover, handleClose: safePolygon() }),
    // @ts-ignore
    useDismiss(context, { referencePointerDown: true }),
  ]);

  return (
    <>
      <div
        ref={reference}
        className="flex h-[4.4rem] w-full cursor-pointer items-center justify-between gap-x-2 px-[1rem] text-inherit transition-colors hover:text-brand-secondary"
        {...getReferenceProps()}
      >
        {label}
        {icon}
        {!hideArrow && <ChevronDownIcon />}
      </div>
      {open && (
        <motion.div
          ref={floating}
          initial={{ opacity: 0, scale: 0.1 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{ duration: 0.2 }}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
            transformOrigin: 'top left',
            zIndex: 100,
          }}
          className={`${
            layout === 'grid' ? 'grid grid-cols-2' : 'flex flex-col'
          } items-start rounded-xl bg-white py-[1.6rem] shadow-lg ${className}`}
        >
          {menuItems.map((item) => {
            if (item.href) {
              return (
                <Link
                  key={item.label}
                  className="mx-[1.2rem] rounded-xl px-[1.2rem] py-[0.8rem] text-brand-black hover:bg-brand-primary-lighter hover:text-brand-secondary"
                  href={item.href}
                >
                  {item.label}
                </Link>
              );
            } else {
              return (
                <button
                  key={item.label}
                  className="mx-[1.2rem] rounded-xl px-[1.2rem] py-[0.8rem] text-left text-brand-black hover:bg-brand-lighter-gray hover:text-brand-secondary"
                  onClick={item.onClick}
                >
                  {item.label}
                </button>
              );
            }
          })}
        </motion.div>
      )}
    </>
  );
};

export default DropdownMenu;
