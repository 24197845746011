import { ShareAltIcon } from '@c/icons';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import { getProductViews } from '@util/firestore/products';
import { ProductDocument } from '@models/product';
import { useAuth } from 'context/AuthContext';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { FaEye } from 'react-icons/fa';

const ShareModal = dynamic(() => import('../modals/ShareModal'));

const ShareOrSellYours = ({ product }: { product: ProductDocument }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { userDoc } = useAuth();
  const { data: views } = useQuery({
    queryKey: ['views', product.id],
    queryFn: () => getProductViews(product.id),
    enabled: !!product.id,
  });
  return (
    <>
      <div className="mt-[2rem] flex flex-col items-center justify-between px-4 sm:flex-row sm:px-0">
        <div className="flex flex-row">
          <p>Have a similar item?</p>
          <Link
            className="ml-2 font-semibold text-brand-secondary"
            href={
              userDoc?.account_activated ? '/create-listing' : '/how-it-works'
            }
          >
            Sell yours.
          </Link>
        </div>
        <div className="flex items-center">
          <Button
            leadingIcon={<ShareAltIcon />}
            text="Share"
            type="text"
            width="small"
            onClick={() => setOpenModal(true)}
          />
          {views?.views && (
            <span className="flex items-center gap-[0.8rem]">
              {/* why +1? So that the user sees the view they just added without needed to refresh from the db */}
              <FaEye /> {views.views + 1}
            </span>
          )}
        </div>
      </div>
      {openModal && (
        <ShareModal
          isOpen={openModal}
          title={product.title}
          thumb={product.thumbnail}
          dismiss={() => setOpenModal(false)}
          id={product.id}
        />
      )}
    </>
  );
};

export default ShareOrSellYours;
