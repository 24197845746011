import Image from 'next/image';

const AcceptedCards = () => (
  <div className="flex flex-row gap-[0.5rem]">
    <Image
      alt="Visa"
      width="50"
      height="34"
      src="/cc_brands/visa.png"
      loading="lazy"
    />
    <Image
      alt="Mastercard"
      width="50"
      height="34"
      src="/cc_brands/mastercard.png"
      loading="lazy"
    />
    <Image
      alt="Amex"
      width="50"
      height="34"
      src="/cc_brands/amex.png"
      loading="lazy"
    />
    <Image
      alt="Discover"
      width="50"
      height="34"
      src="/cc_brands/discover.png"
      loading="lazy"
    />
    <Image
      alt="Affirm"
      width="50"
      height="34"
      src="/cc_brands/affirm_new.png"
      loading="lazy"
    />
  </div>
);

export default AcceptedCards;
