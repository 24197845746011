import { cx } from 'class-variance-authority';
import { Variation } from 'models/product';
import React from 'react';

const VariationSelector = ({
  options,
  selectedOption,
  onSelection,
  title,
  error,
  isSeller,
}: {
  options: Variation[];
  selectedOption?: Variation | null;
  onSelection?: (
    option: Variation,
    isNumber: boolean,
    isColor: boolean
  ) => void;
  title: string;
  error?: boolean;
  isSeller?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-[0.8rem]">
      <span className="text-[1.6rem] font-semibold">{title}</span>
      <div
        className={`flex  max-w-fit flex-wrap  ${
          error ? 'border-red-500' : ''
        }`}
      >
        {options.map((option) => (
          <VariationOption
            key={option.size}
            selected={
              !!selectedOption && selectedOption.size
                ? selectedOption.size === option.size
                : selectedOption?.color
                ? selectedOption.color === option.color
                : false
            }
            isSeller={isSeller}
            option={option}
            onClick={() =>
              onSelection?.(option, option.is_number, !!option.color)
            }
          />
        ))}
      </div>
      {error && (
        <span className="text-[1.2rem] text-red-500">Please select a size</span>
      )}
    </div>
  );
};

function VariationOption({
  selected,
  option,
  onClick,
  isSeller,
}: {
  selected: boolean;
  option: Variation;
  onClick: () => void;
  isSeller?: boolean;
}) {
  return (
    <button
      className={cx(
        'flex h-[4.4rem] min-w-[4.4rem] grow items-center justify-center border-[1px] px-1',
        selected ? 'bg-brand-secondary text-white' : '',
        option.qty <= 0 ? 'pointer-events-none bg-gray-300 text-gray-500' : '',
        isSeller ? 'cursor-default' : 'cursor-pointer'
      )}
      onClick={onClick}
    >
      <span>{option.size ? option.size : option.color}</span>
    </button>
  );
}

export default VariationSelector;
