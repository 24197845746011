import Chat from '@c/Chat';
import ChatModal from '@c/modals/ChatModal';
import {
  getChat,
  markAsRead,
  sendMessageToChat,
} from '@util/firestore/messages';
import { ProductDocument } from '@models/product';
import useRealtimeChats from '@util/hooks/useRealtimeChats';
import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { OrderDocument } from '@models/order';
import { usePathname, useRouter } from 'next/navigation';
import { ReactNode, createContext, useContext, useState } from 'react';
import { useAuth } from './AuthContext';

type ChatProviderProps = {
  children: ReactNode;
};
type ChatContext = {
  chatOpen: boolean;
  openChatDrawer: (chat: ChatDocument) => void;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chatModalOpen: boolean;
  setChatModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openChatModalWithRecipientAndProductId: ({
    recipientUid,
    productId,
  }: {
    recipientUid: string;
    productId?: string;
  }) => void;
  openChatModalWithOrder: (order: OrderDocument, seller_id?: string) => void;
  getChatCount: () => number;
  getUnreadCount: () => number;
  sendMessage: (
    message: ChatMessageDocument,
    chatId: string,
    product?: ProductDocument
  ) => void;
  chats: ChatDocument[] | undefined;
};
const ChatContext = createContext({} as ChatContext);

export function useChat() {
  return useContext(ChatContext);
}

export function ChatProvider({ children }: ChatProviderProps) {
  const [chatOpen, setChatOpen] = useState(false);
  const { userDoc, user } = useAuth();
  const router = useRouter();
  const pathname = usePathname();
  const { realtimeChats: chats } = useRealtimeChats(userDoc?.uid);

  function getChatCount() {
    return chats?.length ?? 0;
  }

  function getUnreadCount() {
    if (!user?.uid || !chats) return 0;
    return chats.reduce(
      (acc, chat) =>
        !chat.is_offer && chat.unread?.[user.uid] ? acc + 1 : acc,
      0
    );
  }

  async function sendMessage(
    message: ChatMessageDocument,
    chatId: string,
    product?: ProductDocument | null
  ) {
    if (userDoc?.roles?.admin) {
      const chat = await getChat(chatId);
      if (chat) {
        sendMessageToChat(message, chat, userDoc.uid, product);
      }
      return;
    }
    const selectedChatDoc = chats?.find((chat) => chat.id === chatId);
    if (!selectedChatDoc) return;
    // preventing sending message if user is not verified, unless they're messaging support (case num)
    if (
      !user?.phoneNumber &&
      !selectedChatDoc?.case_num &&
      !userDoc?.roles?.support
    ) {
      router.push(`/verify-phone?redirect=${encodeURI(pathname ?? '/')}`);
      return;
    }
    sendMessageToChat(message, selectedChatDoc, user?.uid, product);
  }

  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [recipientUid, setRecipientUid] = useState('');
  const [order, setOrder] = useState<OrderDocument | undefined>(undefined);
  const [productId, setProductId] = useState('');

  function openChatModalWithRecipientAndProductId({
    recipientUid,
    productId,
  }: {
    recipientUid: string;
    productId?: string;
  }): void {
    if (user) {
      if (user.uid !== recipientUid) {
        setRecipientUid(recipientUid);
        if (productId) setProductId(productId);
        setChatModalOpen(true);
      }
    } else router.push(`/login?redirect=${encodeURI(pathname ?? '/')}`);
  }

  function openChatModalWithOrder(
    order: OrderDocument,
    recipientUid?: string
  ): void {
    if (user) {
      if (recipientUid) {
        setRecipientUid(recipientUid);
        setOrder(order);
        setChatModalOpen(true);
      } else if (user.uid !== order.buyer_id) {
        setRecipientUid(order.buyer_id);
        setOrder(order);
        setChatModalOpen(true);
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '/')}`);
    }
  }

  const [selectedChat, setSelectedChat] = useState<ChatDocument | undefined>();

  function openChatDrawer(chat: ChatDocument) {
    if (chat?.id && user?.uid && chat.unread?.[user.uid])
      markAsRead(chat.id, user.uid);
    setSelectedChat(chat);
    setChatOpen(true);
  }

  return (
    <ChatContext.Provider
      value={{
        chatOpen,
        setChatOpen,
        openChatDrawer,
        chatModalOpen,
        setChatModalOpen,
        openChatModalWithRecipientAndProductId,
        openChatModalWithOrder,
        getChatCount,
        getUnreadCount,
        sendMessage,
        chats,
      }}
    >
      <Chat chat={selectedChat} setSelectedChat={setSelectedChat} />
      <ChatModal
        isOpen={chatModalOpen}
        dismiss={() => setChatModalOpen(false)}
        recipientUid={recipientUid}
        productId={productId}
        order={order}
      />
      {children}
    </ChatContext.Provider>
  );
}
