import { ButtonToggleType } from '@util/types/buttontoggle';
import { useState } from 'react';

interface ButtonToggleProps {
  buttons: ButtonToggleType[];
}

function renderButtons(
  buttons: ButtonToggleType[],
  setSelectedButton: (key: string) => void,
  selectedButton?: string
) {
  const getRadiusClass = (index: number) => {
    if (index === 0) {
      return 'rounded-l-brand';
    }
    if (index === buttons.length - 1) {
      return 'rounded-r-brand';
    }
    return '';
  };
  return buttons.map((button, index) => {
    return (
      <button
        key={button.key}
        type="button"
        className={
          getRadiusClass(index) +
          `
        ${
          button.key === selectedButton
            ? 'bg-brand-secondary text-white'
            : 'bg-brand-lightest-gray text-black'
        } flex grow items-center justify-center gap-[0.8rem] px-[1.6rem] py-[1.2rem] transition-colors
        ${
          button.disabled
            ? 'pointer-events-none opacity-50'
            : 'hover:bg-brand-secondary hover:text-white'
        }
        `
        }
        onClick={() => {
          setSelectedButton(button.key);
          button.onClick?.();
        }}
      >
        {button.icon}
        {button.label}
      </button>
    );
  });
}

const ButtonToggle = ({ buttons }: ButtonToggleProps) => {
  const initiallySelectedButton = buttons.find((button) => button.selected);
  const [selectedButton, setSelectedButton] = useState(
    initiallySelectedButton?.key
  );
  return (
    <div className="flex">
      {renderButtons(buttons, setSelectedButton, selectedButton)}
    </div>
  );
};

export default ButtonToggle;
