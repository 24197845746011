import useTextOverflow from '@util/hooks/useTextOverflow';
import { ProductDocument } from '@models/product';
import { cx } from 'class-variance-authority';
import sanitize from 'sanitize-html';

const ProductDescription = ({
  product,
  hideReadMore = false,
}: {
  product: ProductDocument;
  hideReadMore?: boolean;
}) => {
  const { readingMore, setReadingMore, overflowingText, overflowActive } =
    useTextOverflow();

  return (
    <>
      <h3 className="mt-[2rem] text-[1.5rem] font-semibold">Description</h3>
      <div>
        {product.description_html ? (
          <>
            <div
              className={`overflow-x-auto overflow-y-hidden ${
                readingMore || hideReadMore
                  ? 'h-fit whitespace-pre-line'
                  : 'line-clamp-4 max-h-[30rem] overflow-hidden'
              }  xl:min-w-[32rem]`}
              dangerouslySetInnerHTML={{
                __html: sanitize(product.description_html),
              }}
            />
            {!hideReadMore && (
              <>
                {!readingMore ? (
                  <button
                    onClick={() => setReadingMore(true)}
                    className="text-brand-secondary"
                  >
                    ...Read more
                  </button>
                ) : (
                  <button
                    onClick={() => setReadingMore(false)}
                    className="text-brand-secondary"
                  >
                    ...Read less
                  </button>
                )}
              </>
            )}
          </>
        ) : product.description ? (
          <>
            <p
              ref={overflowingText}
              className={cx(
                'overflow-x-auto overflow-y-hidden whitespace-pre-line  xl:min-w-[32rem]',
                readingMore || hideReadMore
                  ? 'h-fit'
                  : 'line-clamp-4 max-h-[10rem] overflow-hidden'
              )}
            >
              {product.description}
            </p>
            {overflowActive && !hideReadMore && (
              <>
                {!readingMore ? (
                  <button
                    onClick={() => setReadingMore(true)}
                    className="text-brand-secondary"
                  >
                    ...Read more
                  </button>
                ) : (
                  <button
                    onClick={() => setReadingMore(false)}
                    className="text-brand-secondary"
                  >
                    ...Read less
                  </button>
                )}
              </>
            )}
          </>
        ) : (
          <span className="italic"> No description available</span>
        )}
      </div>
    </>
  );
};

export default ProductDescription;
