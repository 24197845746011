import VariationSelector from '@c/products/VariationSelector';
import { PublicUserDocument } from '@models/user';

import React from 'react';
import BuyAddOfferButtons from './BuyAddOfferButtons';
import { ProductDocument, Variation } from 'models/product';

const BuyerActionButtons = ({
  product,
  seller,
  selectedVariations,
  onSelection,
  errors,
  setErrors,
  onAddToCart,
  hideOffer,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument | null;
  selectedVariations: Variation[] | null;
  errors: string[];
  setErrors?: React.Dispatch<React.SetStateAction<string[]>>;
  onSelection?: (v: Variation, isNumber: boolean, isColor: boolean) => void;
  onAddToCart?: () => void;
  hideOffer?: boolean;
}) => {
  function getQtyOfVariationInStock() {
    if (product.category2 === 'Gear Combos') {
      const qty1 = product.variations?.find(
        (v) =>
          (v.is_number && v.size === selectedVariations?.[0]?.size) ||
          v.size === selectedVariations?.[1]?.size
      )?.qty;
      const qty2 = product.variations?.find(
        (v) =>
          (!v.is_number && v.size === selectedVariations?.[0]?.size) ||
          v.size === selectedVariations?.[1]?.size
      )?.qty;
      return qty1 && qty2 ? Math.min(qty1, qty2) : qty1 || qty2;
    }
    if (!selectedVariations || selectedVariations.length === 0) return 0;
    let qty = 0;
    const letter = selectedVariations.find((v) => !v.is_number && v.size);
    const number = selectedVariations.find((v) => v.is_number && v.size);
    const color = selectedVariations.find((v) => !v.is_number && !v.size);
    product.variations?.forEach((v) => {
      if (
        (v.size === letter?.size || !letter) &&
        (v.size === number?.size || !number) &&
        (v.color === color?.color || !color)
      ) {
        qty += v.qty;
      }
    });
    return qty;
  }
  const uniqueSizes =
    Array.from(
      new Set(
        product.variations?.map((v) => v.size).filter((size) => size !== 'N/A')
      )
    ) ?? [];
  const uniqueColors =
    Array.from(new Set(product.variations?.map((v) => v.color))) ?? [];
  return (
    <>
      <div className="flex flex-col gap-[1.6rem]">
        {/* Variations */}
        {product.has_variations && product.category2 === 'Gear Combos' && (
          <>
            <VariationSelector
              title="Select Jersey Size"
              options={product.variations?.filter((v) => !v?.is_number) ?? []}
              selectedOption={
                selectedVariations?.find((v) => !v?.is_number) ?? null
              }
              onSelection={onSelection}
              error={errors.includes('Jersey')}
            />
            <VariationSelector
              title="Select Pant Size"
              options={product.variations?.filter((v) => v?.is_number) ?? []}
              selectedOption={
                selectedVariations?.find((v) => v?.is_number) ?? null
              }
              onSelection={onSelection}
              error={errors.includes('Pant')}
            />
          </>
        )}
        {product.has_variations && product.category2 !== 'Gear Combos' && (
          <>
            {uniqueSizes.length > 1 && (
              <VariationSelector
                title="Select Size"
                options={uniqueSizes.map((size) => ({
                  size,
                  qty: product.variations
                    ?.filter((v) => v.size === size)
                    ?.reduce((acc, v) => {
                      const selectedColor = selectedVariations?.find(
                        (v) => v.color
                      )?.color;
                      if (selectedColor && v.color !== selectedColor)
                        return acc;
                      return acc + v.qty;
                    }, 0)!,
                  is_number: product.variations?.find((v) => v.size === size)
                    ?.is_number!,
                  price: product.variations?.find((v) => v.size === size)
                    ?.price!,
                }))}
                selectedOption={selectedVariations?.find((v) => v.size) ?? null}
                onSelection={onSelection}
                error={errors.includes('Jersey') || errors.includes('Pant')}
              />
            )}

            {uniqueColors.length > 1 && (
              <VariationSelector
                title="Select Color"
                options={uniqueColors.map((color) => ({
                  color,
                  // qty: product.variations?.find((v) => v.color === color)?.qty!,
                  qty: product.variations
                    ?.filter((v) => v.color === color)
                    ?.reduce((acc, v) => {
                      const selectedSize = selectedVariations?.find(
                        (v) => v.size
                      )?.size;
                      if (selectedSize && v.size !== selectedSize) return acc;
                      return acc + v.qty;
                    }, 0)!,
                  price: product.variations?.find((v) => v.color === color)
                    ?.price!,
                  is_number: false,
                  size: '',
                }))}
                selectedOption={
                  selectedVariations?.find((v) => v.color) ?? null
                }
                onSelection={onSelection}
                error={errors.includes('Color')}
              />
            )}
          </>
        )}
        {/* find the quantity in stock for the given variations selected */}
        {!!selectedVariations?.length &&
          !(
            product.integration_info?.type === 'shopify' &&
            getQtyOfVariationInStock() &&
            getQtyOfVariationInStock() === 9999
          ) && (
            <span className="text-[1.8rem] text-gray-500">
              {getQtyOfVariationInStock()} in stock
            </span>
          )}
        {!product.is_draft && !product.is_auction && (
          <BuyAddOfferButtons
            product={product}
            seller={seller}
            selectedVariations={selectedVariations}
            onAddToCart={onAddToCart}
            onAddToCartError={() => {
              if (!selectedVariations || selectedVariations.length === 0) {
                setErrors?.(['Jersey', 'Pant']);
              } else if (
                product.category2 === 'Gear Combos' &&
                selectedVariations &&
                selectedVariations.length === 1
              ) {
                // find out which variation is missing
                const missingVariation = selectedVariations[0].is_number
                  ? 'Jersey'
                  : 'Pant';
                setErrors?.([missingVariation]);
              }
            }}
            hideOffer={hideOffer}
          />
        )}
      </div>
    </>
  );
};

export default BuyerActionButtons;
