import { CheckmarkIcon, CloseIcon, HandIcon, WarningIcon } from '@c/icons';
import ConfirmModal from '@c/modals/ConfirmModal';
import { ProductDocument } from '@models/product';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { getItem, getItems, logEvent } from '@util/analytics';
import { acceptOffer, getOffer, setOfferState } from '@util/firestore/offers/';
import { calculateEarnings } from '@util/firestore/orders';
import { getProductById } from '@util/firestore/products';
import { formatCurrency, getTimeRemainingParts } from '@util/index';
import { logError } from '@util/logError';
import { getProductSlug } from '@util/urlHelpers';
import { AppStoreButtons } from 'app/get-the-app/components/GetTheApp';
import { useAuth } from 'context/AuthContext';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React from 'react';

interface MessageOfferProps {
  offer_id?: string;
}

export default function MessageOffer({ offer_id }: MessageOfferProps) {
  const [loadingOfferSubmit, setLoadingOfferSubmit] = React.useState(false);

  const { userDoc } = useAuth();
  const router = useRouter();
  const [countdownTimer, setCountdownTimer] = React.useState<number | null>(
    null
  );
  const queryClient = useQueryClient();

  const timeToString = (time: number | null) => {
    if (!time) return '00h : 00m : 00s';
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours}h : ${minutes}m : ${
      seconds < 10 ? '0' + seconds : seconds
    }s`;
  };

  const { data: offer } = useQuery({
    queryKey: ['offer', offer_id],
    queryFn: () => getOffer(offer_id!),
    enabled: !!offer_id,
  });
  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer?.product_id!),
    enabled: !!offer?.product_id,
  });

  const getChip = (state: number) => {
    switch (state) {
      case 0:
        return (
          <Chip text={timeToString(countdownTimer ?? 0)} color="primary" />
        );
      case 1:
        return <Chip text="Accepted" color="success" />;
      case 2:
        return <Chip text="Declined" color="error" />;
      case 3:
        return <Chip text="Cancelled" color="gray" />;
      default:
        return <Chip text="Pending" color="gray" />;
    }
  };

  const declineOffer = async () => {
    if (!offer_id) return;
    await setOfferState(offer_id, 2);
    queryClient.invalidateQueries({
      queryKey: ['offer', offer_id],
    });
    if (product) {
      logEvent(
        'declined_offer',
        {
          items: [getItem(product)],
        },
        userDoc?.uid
      );
    }
    setCountdownTimer(null);
  };

  const acceptThatOffer = async () => {
    if (!offer_id) return;
    setLoadingOfferSubmit(true);
    try {
      const order = await acceptOffer(offer_id);

      logEvent('purchase', {
        transaction_id: order.id,
        value: order.total,
        currency: 'USD',
        tax: order.tax ?? 0,
        shipping: order.shipping_total ?? 0,
        items: getItems([
          product ??
            ({
              id: offer_id,
              title: offer_id,
              price: order.total,
            } as ProductDocument),
        ]),
      });
      if (product) {
        const now = Date.now();
        const created = product.created || product.date_added;
        if (created) {
          logEvent('time_to_match', {
            ...getItem(product),
            msec: now - created,
            hours: dayjs(created).diff(now, 'hours'),
            days: dayjs(created).diff(now, 'days'),
            created,
            sold: now,
          });
        }
      }
      router.push(`/dashboard/orders/${order.id}`);
      setCountdownTimer(null);
    } catch (e) {
      logError(e);
    } finally {
      const offerQ = queryClient.invalidateQueries({
        queryKey: ['offer', offer_id],
      });
      const orderQ = queryClient.invalidateQueries({
        queryKey: ['orders', userDoc?.uid],
      });
      await Promise.all([offerQ, orderQ]);
    }
    setLoadingOfferSubmit(false);
    setAcceptIsOpen(false);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [isAcceptOpen, setAcceptIsOpen] = React.useState(false);
  if (!product || !offer) return null;

  function formatTimeRemaining() {
    if (!offer) return '';
    const time_remaining = offer.created + 172_800_000;
    const timeRemainingInSeconds = (time_remaining - Date.now()) / 1000;
    const { days, hours } = getTimeRemainingParts(timeRemainingInSeconds);
    return `${days}d ${hours}h`;
  }

  return (
    <>
      <div className="flex h-full  w-full flex-col  gap-[2.4rem] overflow-y-auto border-l-[1px] border-[#d9d9d9] p-0 pb-4 @4xl:p-[2.4rem]">
        <Link
          href={getProductSlug(product)}
          target="_blank"
          className="flex w-full flex-col @4xl:w-[35.2rem]"
        >
          <div className="flex w-full items-center justify-end bg-[#ededed] pt-4">
            {offer.state === 1 && (
              <div className="flex flex-col items-center gap-[1.2rem] pr-4">
                {getChip(offer.state)}
              </div>
            )}
            {offer.state === 2 && (
              <div className="flex flex-col items-center gap-[1.2rem] pr-4">
                {getChip(offer.state)}
              </div>
            )}
          </div>
          <div className="flex items-center bg-[#ededed] p-4">
            <div className="relative min-h-[7rem] min-w-[10rem] @4xl:hidden @4xl:h-[35.2rem]">
              <SafeImage
                width={100}
                height={100}
                src={product.thumbnail}
                alt={'product image'}
                skipOptimize
              />
            </div>
            <div className="relative hidden min-h-[10rem] min-w-[10rem] @4xl:block @4xl:h-[35.2rem] @4xl:w-full">
              <SafeImage
                width={100}
                height={100}
                src={product.thumbnail}
                alt={'product image'}
                skipOptimize
                fill
              />
            </div>

            <div className="flex w-full flex-col gap-[0.8rem] bg-[#ededed] px-4 py-[0.8rem] @4xl:hidden sm:gap-0">
              <div className="flex w-full grow flex-row gap-[0.4rem] @4xl:flex-col ">
                <span className="line-clamp-2 text-[1.6rem] font-semibold">
                  {product.title}
                </span>
                {/* {offer.variation?.letter && (
                  <span className="whitespace-nowrap text-[1.3rem] font-medium text-brand-gray">
                    Size: {offer.variation.letter}
                  </span>
                )} */}
                {/* {offer.variation?.number && (
                  <span className="whitespace-nowrap text-[1.3rem] font-medium text-brand-gray">
                    Size: {offer.variation.number}
                  </span>
                )} */}
              </div>
              <div className="flex flex-col gap-[0.8rem] pt-4">
                <span className="text-[1.6rem] font-medium leading-[1.4rem] sm:text-[1.4rem]">
                  Offer: {formatCurrency(offer.price ?? 0)}
                </span>
                <span className="text-[1.4rem] font-medium leading-[1.4rem] text-[#7f7f7f] line-through">
                  Original: {formatCurrency(product.price ?? 0)}
                </span>
                {offer.state === 0 && (
                  <span className="inline min-h-[2rem] sm:hidden">
                    <>Expires in {formatTimeRemaining()}</>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="relative hidden w-full @4xl:block">
            <div className="flex min-h-[10rem]  items-end justify-between gap-[0.4rem] whitespace-nowrap bg-[#ededed] px-[1.2rem] py-[1.6rem] text-[1.1rem] @4xl:h-auto @4xl:flex-row @4xl:items-end @4xl:text-[1.6rem]">
              <div className="flex flex-col justify-between gap-[1.6rem]">
                <strong className="self-start text-[2.4rem] leading-[2.4rem]">
                  Offer
                </strong>

                <span className="min-h-[2rem]">
                  {offer.state === 0 && <>Expires in {formatTimeRemaining()}</>}
                </span>
              </div>
              <div className="flex flex-col items-end gap-[0.8rem]">
                <span className="text-[2.4rem] font-semibold leading-[3.2rem]">
                  {formatCurrency(offer.price ?? 0)}
                </span>
                <span className="text-[1.6rem] font-semibold leading-[3.2rem] text-[#7f7f7f] line-through">
                  {formatCurrency(product.price ?? 0)}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-[1.6rem]  hidden  w-full flex-col gap-[0.4rem] @4xl:flex">
            <div className="mt-[1.6rem] flex w-full flex-row gap-[0.4rem] @4xl:flex-col">
              <span className="text-[2.2rem] font-semibold">
                {product.title}
              </span>
              {/* {offer.variation?.letter && (
                <span className="text-[1.3rem] font-medium text-brand-gray">
                  Size: {offer.variation?.letter}
                </span>
              )}
              {offer.variation?.number && (
                <span className="text-[1.3rem] font-medium text-brand-gray">
                  Size: {offer.variation?.number}
                </span>
              )} */}
            </div>
          </div>
        </Link>
        <div className="flex flex-row-reverse gap-[1.2rem]  px-4 @4xl:w-full  sm:flex-col">
          {offer.state === 0 &&
            offer.seller_id === userDoc?.uid &&
            !offer.is_counter &&
            !offer.is_exclusive && (
              <>
                <Button
                  text="Accept Offer"
                  type="success"
                  width="fluid"
                  onClick={() => setAcceptIsOpen(true)}
                  leadingIcon={<CheckmarkIcon />}
                />
                <Button
                  type="error"
                  width="fluid"
                  text="Decline Offer"
                  onClick={() => setIsOpen(true)}
                  leadingIcon={<CloseIcon />}
                />
              </>
            )}
          {offer.state === 0 && !!(offer.is_counter || offer.is_exclusive) && (
            <div className="flex flex-col gap-4">
              <p className="flex items-center gap-2 rounded-brand border-2 border-brand-secondary bg-brand-secondary/30 p-2 text-[1.5rem] font-semibold">
                <WarningIcon />
                {offer.is_counter ? 'Counter' : 'Exclusive'} Offer: Please
                Continue in App
              </p>
              <div className="flex gap-[2.4rem]">
                <Button
                  text="Accept Offer"
                  type="success"
                  height="small"
                  width="fluid"
                  leadingIcon={<CheckmarkIcon />}
                  disabled={true}
                />
                <Button
                  type="error"
                  width="fluid"
                  height="small"
                  text="Decline Offer"
                  leadingIcon={<CloseIcon />}
                  disabled={true}
                />
              </div>
              <AppStoreButtons />
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        title="Decline Offer?"
        body="Are you sure you want to decline this offer? You can not undo this action."
        buttonText="Decline Offer"
        isOpen={isOpen}
        dismiss={(confirm) => {
          setIsOpen(false);
          if (confirm) declineOffer();
        }}
      />
      <ConfirmModal
        title="Accept Offer?"
        icon={<HandIcon />}
        btnType="success"
        body={`By accepting this offer, you agree to sell this item for $${offer?.total.toFixed(
          2
        )} ($${offer?.price?.toFixed(2)} + $${offer?.shipping_cost.toFixed(
          2
        )} Shipping + $${offer?.tax?.toFixed(
          2
        )} Tax). Your earnings for this offer will be ${formatCurrency(
          calculateEarnings({
            item: {
              product_cost: offer.price ?? 0,
              seller_id: offer.seller_id,
              qty: 1,
              buyer_shipping_cost: offer.shipping_cost,
              product_tax: offer.tax,
              rate_id: offer.rate_id,
              refund_amount: 0,
              insurance_amount: 0,
            },
            item_count: 1,
            fee: userDoc?.fee,
            created: offer.created,
          })[0]
        )}. This action is binding and cannot be cancelled. An order will be created and can be viewed in your seller dashboard.`}
        buttonText="Accept Offer"
        isOpen={isAcceptOpen}
        loading={loadingOfferSubmit}
        dismiss={(confirm) => {
          if (confirm) acceptThatOffer();
          else setAcceptIsOpen(false);
        }}
      />
    </>
  );
}

// function Timer() {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M12 20C10.1435 20 8.36301 19.2625 7.05025 17.9497C5.7375 16.637 5 14.8565 5 13C5 11.1435 5.7375 9.36301 7.05025 8.05025C8.36301 6.7375 10.1435 6 12 6C13.8565 6 15.637 6.7375 16.9497 8.05025C18.2625 9.36301 19 11.1435 19 13C19 14.8565 18.2625 16.637 16.9497 17.9497C15.637 19.2625 13.8565 20 12 20ZM19.03 7.39L20.45 5.97C20 5.46 19.55 5 19.04 4.56L17.62 6C16.07 4.74 14.12 4 12 4C9.61305 4 7.32387 4.94821 5.63604 6.63604C3.94821 8.32387 3 10.6131 3 13C3 15.3869 3.94821 17.6761 5.63604 19.364C7.32387 21.0518 9.61305 22 12 22C17 22 21 17.97 21 13C21 10.88 20.26 8.93 19.03 7.39ZM11 14H13V8H11M15 1H9V3H15V1Z"
//         fill="#C41719"
//       />
//     </svg>
//   );
// }

// function HandOffer() {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M21 13C21.6 13 22.1 13.2 22.4 13.6C22.8 14 23 14.5 23 15L15 18L8 16V7H9.9L17.2 9.7C17.7 9.9 18 10.3 18 10.8C18 11.1 17.9 11.4 17.7 11.6C17.5 11.8 17.2 12 16.8 12H14L12.3 11.3L12 12.2L14 13H21ZM2 7H6V18H2V7Z"
//         fill="#C41719"
//       />
//     </svg>
//   );
// }
