import { db, functions, httpsCallable } from '@util/firebase';
import {
  CollectionReference,
  DocumentReference,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { removeUndefinedRecursive } from '../products';
import { userRef } from '../users';
import {
  IntegrationDocument,
  IntegrationSyncError,
  IntegrationTypes,
  UserIntegration,
} from './integrations.types';
import { ProductDocument } from 'models/product';

type args = { type: IntegrationTypes; uid?: string };

export const colRef = collection(
  db,
  'integrations'
) as CollectionReference<IntegrationDocument>;

export async function getIntegrationList() {
  const { docs } = await getDocs(colRef);
  return docs.map((doc) => doc.data());
}

export async function getIntegrationById(id: string) {
  const snap = await getDoc(doc(colRef, id));
  return snap.data() ?? null;
}

export async function getShopifyIntegrationByUsername(username: string) {
  const c = collection(db, 'integrations');
  const q = query(c, where('username', '==', username));
  const { docs } = await getDocs(q);
  return docs[0]?.data() ?? null;
}

export async function getUserIntegrations(uid: string) {
  if (!uid) return null;
  const col = collection(userRef, uid, 'integrations');
  const { docs } = await getDocs(col);
  return docs.map((doc) => doc.data() as UserIntegration);
}

export async function getAllUserIntegrations(type: IntegrationTypes) {
  if (!type) return null;
  const ref = collectionGroup(db, 'integrations');
  const q = query(ref, where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data() as UserIntegration);
}

export async function createUserIntegration(
  uid: string,
  type: IntegrationTypes,
  data: Omit<UserIntegration, 'settings' | 'cat_mapper'>
) {
  const ref = doc(userRef, uid, 'integrations', type);
  return setDoc(ref, data as any); // typescript thinks i need to send a userdoc here
}

export async function createIntegrationAdmin({
  uid,
  username,
  client_id,
  client_secret,
  store_domain,
  install_link,
  type,
}: {
  uid: string;
  username: string;
  client_id: string;
  client_secret: string;
  store_domain: string;
  install_link: string;
  type: IntegrationTypes;
}) {
  const ref = doc(userRef, uid, 'integrations', type);
  await setDoc(ref, {
    uid,
    username,
    client_id,
    client_secret,
    store_domain,
    install_link,
    type,
    cat_map_type: 'auto',
  } as any);
  // update user doc to have role of integrations
  const ref2 = doc(userRef, uid);
  await updateDoc(ref2, { 'roles.integrations': true });
}

export function getUserIntegrationQuery(uid: string, type: IntegrationTypes) {
  if (!uid) return null;
  const ref = doc(db, 'users', uid, 'integrations', type);
  return ref as DocumentReference<UserIntegration>;
}

export async function getUserIntegration(uid: string, type: IntegrationTypes) {
  if (!uid) return null;
  const ref = doc(db, 'users', uid, 'integrations', type);
  const snap = await getDoc(ref);
  return (snap.data() as UserIntegration) ?? null;
}

export async function setUserIntegrationByKey(
  uid: string,
  type: IntegrationTypes,
  key: keyof UserIntegration,
  value: any
) {
  const val = removeUndefinedRecursive(value);
  const ref = doc(db, 'users', uid, 'integrations', type);
  return updateDoc(ref, { [key]: val });
}

export async function updateUserIntegration(
  uid: string,
  type: IntegrationTypes,
  // allow fields or fieldtypes (deleteField, addField, etc)
  data: Partial<UserIntegration> | { [key: string]: any }
) {
  const ref = doc(db, 'users', uid, 'integrations', type);
  return updateDoc(ref, data);
}

export async function initialSetup(type: IntegrationTypes, code: string) {
  const { data } = await httpsCallable<
    args & { code: string },
    { account_name: string }
  >(
    functions,
    'initialSetup'
  )({ type, code });
  return data;
}

export async function getCategories(type: IntegrationTypes) {
  const { data } = await httpsCallable<args, string[]>(
    functions,
    'integrations-v2_getShopifyCategories'
  )({ type });
  return data;
}

export async function getCollections(type: IntegrationTypes) {
  try {
    const { data } = await httpsCallable<args, any[]>(
      functions,
      'integrations-v2_getShopifyCollections'
    )({ type });
    return data as { id: number; title: string }[];
  } catch (e) {
    alert(e);
    return [];
  }
}

export async function initialInventorySync(type: IntegrationTypes) {
  await httpsCallable<args, { imported: number; total: number }>(
    functions,
    'integrations-v2_enqueueFullSync',
    { timeout: 10 * 60 * 1000 } // 10 minutes
  )({ type });
  return;
}

export async function deleteInventoryFromSync(
  type: IntegrationTypes,
  uid?: string
) {
  const { data } = await httpsCallable<args, { deleted: number }>(
    functions,
    'integrations-v2_deleteInventoryFromSync'
  )({ type, uid });
  return data;
}

export async function removeIntegration({
  uid,
  type,
}: {
  uid: string;
  type: IntegrationTypes;
}) {
  const { data } = await httpsCallable<args, void>(
    functions,
    'integrations-v2_removeIntegration'
  )({ type, uid });
  return data;
}

export async function setupWebhooks() {
  // v2_setupWebhooks
  await httpsCallable<args, void>(
    functions,
    'integrations-v2_setupWebhooks'
  )({
    type: 'shopify',
  });
}
export async function getIntegrationErrors({
  type,
  uid,
}: {
  type: IntegrationTypes;
  uid?: string;
}) {
  if (!uid) return [];
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data()) as IntegrationSyncError[];
}

export async function getIntegrationErrorByProductId(
  productId: string,
  sellerId: string
) {
  const col = collection(db, 'integration_errors');
  const q = query(
    col,
    where('uid', '==', sellerId),
    where('existing_product_id', '==', productId),
    limit(1)
  );
  const { docs } = await getDocs(q);
  return docs[0]?.data() as IntegrationSyncError;
}

export async function getIntegrationErrorCount({
  type,
  uid,
}: {
  type: IntegrationTypes;
  uid?: string;
}) {
  if (!uid) return 0;
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const res = await getCountFromServer(q);
  return res.data().count;
}

export async function deleteProductsFromSync({
  ids,
}: {
  ids: string[];
}): Promise<void> {
  await httpsCallable<{ ids: string[] }, void>(
    functions,
    'integrations-v2_deleteProductsFromSync'
  )({ ids });
}

export async function deleteIntegrationError(id: string) {
  const ref = doc(db, 'integration_errors', id);
  // delete the error
  await deleteDoc(ref);
}

export async function deleteAllIntegrationErrors({
  uid,
  type,
}: {
  uid: string;
  type: IntegrationTypes;
}) {
  const col = collection(db, 'integration_errors');
  const q = query(col, where('uid', '==', uid), where('type', '==', type));
  const { docs } = await getDocs(q);
  await Promise.all(docs.map((doc) => deleteDoc(doc.ref)));
}
export async function createIntegrationRequest({
  email,
  store_domain,
  type,
  uid,
}: {
  email: string;
  store_domain: string;
  type: IntegrationTypes;
  uid?: string;
}) {
  const ref = doc(db, 'integration_requests', store_domain);
  await setDoc(ref, { type, email, store_domain, ...(uid && { uid }) });
}

export async function getIntegrationRequests(type: IntegrationTypes) {
  const col = collection(db, 'integration_requests');
  const q = query(col, where('type', '==', type));
  const { docs } = await getDocs(q);
  return docs.map(
    (doc) =>
      doc.data() as {
        email: string;
        store_domain: string;
        type: IntegrationTypes;
        uid?: string;
      }
  );
}

export async function deleteIntegrationRequest(store_domain: string) {
  const ref = doc(db, 'integration_requests', store_domain);
  await deleteDoc(ref);
}

export async function getIntegrationRequestsCount() {
  const col = collection(db, 'integration_requests');
  const res = await getCountFromServer(col);
  return res.data().count;
}

export async function retryCategorization(product_id: string) {
  await httpsCallable<{ product_id: string }>(
    functions,
    'integrations-v2_retryCategorySync'
  )({ product_id });
}

// Ebay
export async function ebayFullSync() {
  const res = await httpsCallable<{ uid: string }, ProductDocument[]>(
    functions,
    'integrations-ebay-v2_ebayFullSync'
  )();
  return res.data;
}

export async function completeAuthentication(code: string) {
  const res = await httpsCallable<{ code: string }, void>(
    functions,
    'integrations-ebay-v2_completeAuthentication'
  )({ code });
  return res.data;
}

export async function ebayReviseInventory({
  newInventory,
  product,
  variationSku,
}: {
  newInventory: number;
  product: ProductDocument;
  variationSku?: string;
}) {
  const res = await httpsCallable<
    {
      newInventory: number;
      product: ProductDocument;
    },
    ProductDocument[]
  >(
    functions,
    'integrations-ebay-v2_reviseInventoryStatus'
  )({
    newInventory,
    product,
    ...(variationSku && { variationSku }),
  });
  return res.data;
}
