import { useQuery } from '@tanstack/react-query';
import { getLiveListingsCountFromTypesense } from '@util/firestore/products';

const useListingCount = (uid?: string) => {
  const { data } = useQuery({
    queryKey: ['listingCount', uid],
    queryFn: () => getLiveListingsCountFromTypesense(uid!),
    enabled: !!uid,
  });
  return data ?? 0;
};

export default useListingCount;
