import { EyeIcon } from '@c/icons';
import { useQuery } from '@tanstack/react-query';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { getItem, logEvent } from '@util/analytics';
import { getBidsCountByProductId } from '@util/firestore/bid/bid.service';
import { ProductDocument } from '@models/product';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import { PublicUserDocument } from '@models/user';
import useCountdown from '@util/hooks/useCountdown';
import { formatCurrency, formatTimestamp } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import QuickViewModal from 'features/shop/components/QuickViewModal';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { OrderItemDocument } from 'models/order';
import { useAttributionContext } from 'context/AttributionContext';
import { useSearch } from 'context/SearchContext';

const MXListProductCard = ({
  product,
  attribution,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument;
  attribution?: OrderItemDocument['attribution'];
}) => {
  const [quickViewOpen, setQuickViewOpen] = useState(false);
  const pathname = usePathname() ?? '';
  const { userDoc } = useAuth();
  const { setAttribution } = useAttributionContext();
  const { filters } = useSearch();
  return (
    <div className="group relative">
      <Link
        href={getProductSlug(product)}
        className="flex flex-col items-center self-stretch overflow-hidden rounded-brand bg-white shadow-lg lg:flex-row lg:items-start"
        onClick={() => {
          logEvent('select_item', {
            items: [getItem(product)],
            item_list_id: pathname,
            item_list_name: pathname,
          });
          setAttribution(product.id, attribution);
          if (pathname.includes('search')) {
            if (userDoc)
              trackUserInteraction({
                uid: userDoc.uid,
                pid: product.id,
                interaction: 'search_clicked',
              });
            if (filters?.term) {
              trackUserSearch({
                ...filters,
                product_clicked: product.id,
              });
            }
          }
          // algoliaInsights('clickedObjectIDs', {
          //   index: process.env.NEXT_PUBLIC_IS_STAGING ? 'products' : 'products',
          //   eventName: 'product card clicked',
          //   objectIDs: [product.id],
          // });
        }}
      >
        <ProductPicture product={product} />
        <ProductInfo product={product} />
      </Link>
      {product.category !== 'Dirt Bikes' && (
        <div className="hidden opacity-0 delay-0 duration-200 ease-linear group-hover:opacity-100 lg:block">
          <div className="flex w-full justify-center">
            <button
              className="absolute bottom-[2rem] left-[4rem] flex gap-[0.8rem] rounded-full bg-black p-4 text-white transition-transform  hover:scale-110 "
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAttribution(product.id, attribution);
                setQuickViewOpen(true);
              }}
            >
              QuickView
              <EyeIcon />
            </button>
          </div>
        </div>
      )}

      <QuickViewModal
        product={product}
        isOpen={quickViewOpen}
        dismiss={() => setQuickViewOpen(false)}
      />
    </div>
  );
};

const SoldOutOverlay = () => (
  <div className="absolute inset-0 mx-auto flex h-[5rem] items-center justify-center">
    <span className="absolute z-10 text-[2.2rem] font-semibold tracking-wider text-white">
      SOLD
    </span>
    <div className="absolute h-full w-full bg-brand-secondary opacity-80" />
  </div>
);
const ProductPicture = ({ product }: { product: ProductDocument }) => {
  return (
    <div className="relative my-auto  min-h-[20rem] min-w-[20rem]">
      <SafeImage
        src={product.thumbnail}
        alt={`Product image for - ${product.title}`}
        fill
        className="bg-repeat object-cover"
      />
      {product.out_of_stock && !product.is_auction && <SoldOutOverlay />}
    </div>
  );
};

const ProductAuctionCountdown = ({ product }: { product: ProductDocument }) => {
  const isStarted = product.start_time! < Date.now();
  const timeRemaining = useCountdown(
    product.start_time!,
    product.end_time!,
    isStarted ? 'end' : 'start'
  );
  return (
    <div className="mt-4 flex w-full items-center gap-[0.4rem] ">
      <div className="flex w-full items-center justify-center gap-[0.8rem] rounded-b-2xl">
        <span
          suppressHydrationWarning
          className={`w-full font-medium ${
            isStarted ? 'text-brand-green' : 'text-brand-secondary'
          }`}
        >
          {isStarted ? 'Ends in ' : 'Starts in '}
          {timeRemaining.days > 0 && `${timeRemaining.days}d `}
          {timeRemaining.hours}h {timeRemaining.minutes}m{' '}
          {timeRemaining.seconds}s
        </span>
      </div>
    </div>
  );
};

const ProductInfo = ({ product }: { product: ProductDocument }) => {
  const { data: bidCount } = useQuery({
    queryKey: ['bidCount', product.id],
    queryFn: () => getBidsCountByProductId(product.id),
    enabled: product.is_auction,
  });

  let fullPrice = product.price;

  if (product.variations?.length) {
    const letterSize = product.variations.find((v) => !v.is_number);
    const numberSize = product.variations.find((v) => v.is_number);
    if (letterSize && numberSize) {
      fullPrice = letterSize.price + numberSize.price;
    } else if (letterSize) {
      fullPrice = letterSize.price;
    } else if (numberSize) {
      fullPrice = numberSize.price;
    }
  }
  return (
    <div className="flex w-full flex-col gap-[2.4rem] self-stretch bg-white p-[2rem]">
      <div className="flex w-full grow self-stretch">
        {/* description */}
        <div className="relative flex w-full flex-col gap-[1.6rem]">
          <div className="flex flex-col gap-[0.4rem]">
            {product.out_of_stock && product.sold_date && (
              <span className="line-clamp-1 text-[1.6rem] font-medium text-green-500">
                Sold on {formatTimestamp(product.sold_date, true)}
              </span>
            )}
            <span className="line-clamp-2 text-[2.2rem]">{product.title}</span>
            {product.is_auction && (
              <div className=" flex w-full items-center justify-between gap-[0.8rem]">
                <Chip text={'Auction'} color={'primary'} />
              </div>
            )}
            {product.is_auction && (
              <ProductAuctionCountdown product={product} />
            )}
            <span className="text-[#7F7F7F]">{product.condition}</span>
          </div>

          <span className="line-clamp-2 text-[1.4rem] text-[#333333]">
            {product.description}
          </span>
          <div className="flex">
            {product.is_flat_rate &&
              product.shipping_costs?.every(({ cost }) => cost === 0) && (
                <>
                  <Truck />
                  <span className="text-[1.4rem] text-[#333333]">
                    Free Shipping
                  </span>
                </>
              )}
          </div>
        </div>
        {/* price */}
        <div className="flex flex-col items-end gap-[0.8rem] lg:w-[16rem]">
          {product.on_sale ? (
            <>
              <span className="text-[2.2rem] font-semibold text-brand-secondary">
                {formatCurrency(fullPrice)}
              </span>
              <span className="text-[1.4rem] text-[#7f7f7f] line-through">
                {formatCurrency(product.og_price)}
              </span>
              <DiscountBadge product={product} />
            </>
          ) : (
            <span className="text-[2.2rem] font-semibold">
              {formatCurrency(fullPrice)}
            </span>
          )}
          {bidCount !== undefined && (
            <span className="whitespace-nowrap text-[1.4rem] font-medium text-gray-500">
              {bidCount !== undefined ? `(${bidCount} bids)` : ''}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const DiscountBadge = ({ product }: { product: ProductDocument }) => {
  const discount = Math.floor(
    ((product.og_price - product.price) / product.og_price) * 100
  );
  return (
    <div className="rounded-[0.4rem] bg-brand-secondary px-[1rem] py-[0.4rem]">
      <span className="text-[1.5rem] font-semibold text-white">
        -{discount}% off
      </span>
    </div>
  );
};

export const Truck = ({ className }: { className?: string }) => (
  <svg
    width={className ? '' : 24}
    height={className ? '' : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 13.2188H3.75V6.45833C3.75 6.33678 3.7983 6.2202 3.88425 6.13424C3.9702 6.04829 4.08678 6 4.20833 6H14.2917C14.4132 6 14.5298 6.04829 14.6158 6.13424C14.7017 6.2202 14.75 6.33678 14.75 6.45833V13.2188Z"
      fill="#C41719"
    />
    <path
      d="M15.4123 15.7969H14.75M14.75 8.0625H17.9583C18.027 8.06254 18.0948 8.07801 18.1566 8.10776C18.2185 8.1375 18.2729 8.18078 18.3158 8.23438L20.1492 10.526C20.2143 10.6073 20.2499 10.7083 20.25 10.8125V15.3477C20.25 15.4693 20.2017 15.5858 20.1158 15.6718C20.0298 15.7577 19.9132 15.806 19.7917 15.806H18.3273M16.5833 8.07166V10.7071C16.5833 10.8286 16.6316 10.9452 16.7176 11.0312C16.8035 11.1171 16.9201 11.1654 17.0417 11.1654H20.25M14.75 13.2188H3.75V6.45833C3.75 6.33678 3.7983 6.2202 3.88425 6.13424C3.9702 6.04829 4.08678 6 4.20833 6H14.2917C14.4132 6 14.5298 6.04829 14.6158 6.13424C14.7017 6.2202 14.75 6.33678 14.75 6.45833V13.2188ZM14.75 13.2188V15.7969H7.27C7.16405 15.4949 6.96691 15.2332 6.70581 15.0481C6.44471 14.863 6.13256 14.7636 5.8125 14.7636C5.49244 14.7636 5.18029 14.863 4.91919 15.0481C4.65809 15.2332 4.46095 15.4949 4.355 15.7969H4.20833C4.08678 15.7969 3.9702 15.7486 3.88425 15.6626C3.7983 15.5767 3.75 15.4601 3.75 15.3385V13.2188H14.75ZM7.35938 16.3217C7.35938 16.7319 7.1964 17.1254 6.9063 17.4155C6.61621 17.7056 6.22276 17.8685 5.8125 17.8685C5.40224 17.8685 5.00879 17.7056 4.7187 17.4155C4.4286 17.1254 4.26563 16.7319 4.26563 16.3217C4.26451 16.1458 4.29478 15.9712 4.355 15.806C4.46095 15.504 4.65809 15.2424 4.91919 15.0573C5.18029 14.8722 5.49244 14.7728 5.8125 14.7728C6.13256 14.7728 6.44471 14.8722 6.70581 15.0573C6.96691 15.2424 7.16405 15.504 7.27 15.806C7.33022 15.9712 7.36049 16.1458 7.35938 16.3217V16.3217ZM18.4167 16.3217C18.4174 16.7098 18.2724 17.0841 18.0104 17.3704C17.7484 17.6568 17.3884 17.8343 17.0017 17.868C16.6151 17.9016 16.2299 17.7889 15.9223 17.5521C15.6148 17.3153 15.4074 16.9716 15.3411 16.5892C15.2748 16.2068 15.3545 15.8134 15.5644 15.4869C15.7743 15.1604 16.0991 14.9246 16.4745 14.8261C16.85 14.7277 17.2487 14.7737 17.5918 14.9552C17.9349 15.1367 18.1974 15.4403 18.3273 15.806C18.3861 15.9716 18.4164 16.146 18.4167 16.3217V16.3217Z"
      stroke="black"
      strokeWidth="0.5625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03771 8.15649L5.35938 10.533H5.93917L6.59917 8.15879L6.03771 8.15649Z"
      fill="white"
    />
    <path
      d="M7.54096 8.16111L6.88555 8.88298L6.65638 8.15881L6.4043 9.00673L6.5945 9.85465L7.29576 9.00902L7.54096 8.16111Z"
      fill="white"
    />
    <path
      d="M7.48604 10.5375L8.16209 8.16101H7.58L6.90625 10.5352L7.48604 10.5375Z"
      fill="white"
    />
    <path
      d="M8.7711 10.5421L9.42882 10.5444L9.13549 9.27938L10.0567 8.16792L9.50444 8.16562L9.01173 8.77063L8.87194 8.16333H8.21882L8.49381 9.35271L6.71777 11.4633H7.27923L8.61757 9.88896L8.7711 10.5421Z"
      fill="white"
    />
    <path
      d="M8.12024 10.675H8.0217L7.86816 11.2228H8.22337L8.2417 11.1425L7.98733 11.138L8.12024 10.675Z"
      fill="white"
    />
    <path
      d="M8.55053 11.0943L8.50471 11.1333H8.38325L8.35575 11.0989L8.44054 10.801L8.48407 10.7666H8.60324L8.63533 10.801L8.55053 11.0943ZM8.672 10.6772H8.46574L8.35345 10.7597L8.24805 11.1379L8.31678 11.2273H8.51615L8.63533 11.1333L8.74076 10.762L8.672 10.6772Z"
      fill="white"
    />
    <path
      d="M8.98452 11.021L8.96618 11.1035L8.92264 11.1356L8.80806 11.1333L8.78285 11.0943L8.86306 10.8079L8.91119 10.7689H9.02806L9.04869 10.8079L9.03264 10.872L9.13118 10.8743L9.15868 10.7575L9.09452 10.6772H8.89973L8.77598 10.7712L8.67285 11.1287L8.74848 11.2295H8.93869L9.05327 11.1379L9.08306 11.0233L8.98452 11.021Z"
      fill="white"
    />
    <path
      d="M9.61675 10.6795H9.51133L9.26154 10.8995L9.32113 10.6795L9.22029 10.6772L9.06445 11.2295H9.16987L9.22258 11.0393L9.31654 10.9568L9.36925 11.2318H9.46549L9.39675 10.8789L9.61675 10.6795Z"
      fill="white"
    />
    <path
      d="M9.66471 10.9798H9.84804L9.86637 10.9064H9.68304L9.7197 10.7712H9.98554L10.0107 10.6796H9.65554L9.49512 11.2319H9.85491L9.88012 11.1402H9.62116L9.66471 10.9798Z"
      fill="white"
    />
    <path
      d="M10.0772 10.9157L10.1162 10.7736H10.2606L10.2812 10.7988L10.2629 10.8836L10.2239 10.9203L10.0772 10.9157ZM10.3499 10.9157L10.3912 10.7598L10.3339 10.6842H10.0497L9.8916 11.2365H9.98786L10.052 11.0073H10.1185L10.1551 11.2365H10.2537L10.2147 11.0073H10.2422L10.3499 10.9157Z"
      fill="white"
    />
  </svg>
);
export default MXListProductCard;
