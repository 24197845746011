import MXProductCard from '@c/cards/MXProductCard';
import { LogoWithoutText } from '@c/icons/logoWithoutText';
import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import { Popover } from '@headlessui/react';
import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { PublicUserDocument } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { deleteMessage } from '@util/firestore/messages';
import { getProductBySlug } from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { formatMessage, formatTimestamp } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import Link from 'next/link';
import { useState } from 'react';

const IMG_SIZE = 500;

const MessageImage = ({
  message,
  img,
  fallbackImg,
  onClick,
}: {
  message: ChatMessageDocument;
  img: string;
  fallbackImg?: string;
  onClick: (src: string) => void;
  isInbound?: boolean;
}) => {
  if (message.mime_type?.includes('video') && message.content) {
    return (
      <video controls width={IMG_SIZE}>
        <source width={IMG_SIZE} src={message.content}></source>
      </video>
    );
  }
  return (
    <div className="relative h-[20rem] w-[20rem]">
      <SafeImage
        src={img}
        key={img}
        fill
        alt={'img'}
        fallbackSrc={fallbackImg}
        className="cursor-pointer object-cover"
        onClick={() => onClick(img)}
      />
    </div>
  );
};

interface MessageBubbleProps {
  chat: ChatDocument;
  chatMessage: ChatMessageDocument;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

interface MessageProps {
  message: ChatMessageDocument;
  timestamp: number;
  isSupport?: boolean;
  user?: PublicUserDocument | null;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

const MessageOutbound = ({
  message,
  timestamp,
  fallbackImg,
  onImageClick,
  user,
  isSupport,
}: MessageProps) => (
  <div className="flex max-w-[80%] flex-col items-end gap-[1.6rem] justify-self-end">
    {!message.is_img ? (
      <div className="flex flex-col items-end gap-[0.4rem]">
        <div className="flex gap-[0.8rem]">
          {message.content.includes('https://mxlocker.com/product/') ? (
            <MessageProduct
              slug={
                message.content.split('/')[
                  message.content.split('/').length - 1
                ]
              }
            />
          ) : (
            <div className="flex flex-col self-end rounded-chatRight bg-brand-secondary p-[1.6rem] text-right text-[1.6rem] font-semibold text-white">
              <p
                className="message-content max-w-[80vw] whitespace-pre-line text-[1.3rem]"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(message.content).html,
                }}
              ></p>
            </div>
          )}
          {user?.uid === process.env.NEXT_PUBLIC_SUPPORT_ID ? (
            <div className="hidden h-min items-center justify-center rounded-full p-3 sm:flex">
              <LogoWithoutText />
            </div>
          ) : isSupport ? (
            <Link
              className="hidden shrink-0 sm:block "
              href={`/profile/${user?.username_slug}`}
            >
              <Avatar size="extraSmall" user={user} />
            </Link>
          ) : null}
        </div>
        {!!timestamp && (
          <p className="mr-4 text-[1.3rem] text-brand-gray ">
            {formatTimestamp(timestamp)}
          </p>
        )}
      </div>
    ) : (
      <MessageImage
        message={message}
        img={message.thumbnail ?? message.content}
        fallbackImg={message.download_url ?? fallbackImg}
        onClick={onImageClick}
      />
    )}
  </div>
);

const MessageProduct = ({ slug }: { slug: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['product', slug],
    queryFn: () =>
      getProductBySlug({ slug: slug.split(' ')[0].replace('.', '') }),
    enabled: !!slug,
  });
  const product = data?.results;
  if (isLoading) return <ProductCardSkeleton />;
  if (!product) return null;
  return (
    <>
      <MXProductCard
        product={product}
        attribution={{
          page: 'chat',
          section: 'chat',
          data_source: 'firebase',
        }}
      />
    </>
  );
};

const MessageInbound = ({
  message,
  timestamp,
  isSupport,
  user,
  onImageClick,
}: MessageProps) => (
  <div className="flex max-w-[80%] items-end  gap-[1.2rem] self-start py-[1.6rem] text-[1.6rem]">
    {isSupport ? (
      <div className="hidden h-min items-center justify-center rounded-full p-3 text-brand-white sm:flex">
        <LogoWithoutText />
      </div>
    ) : (
      <Link
        className="hidden shrink-0 pb-[1.9rem] sm:block"
        href={`/profile/${user?.username_slug}`}
      >
        <Avatar size="extraSmall" user={user} />
      </Link>
    )}
    <div className="flex flex-col items-start gap-[1.6rem]">
      {!message.is_img ? (
        <div className="flex flex-col gap-[0.4rem]">
          {message.content.includes('https://mxlocker.com/product/') && (
            <MessageProduct
              slug={
                message.content.split('/')[
                  message.content.split('/').length - 1
                ]
              }
            />
          )}
          <div
            className={`flex flex-col gap-[0.4rem] rounded-chatLeft  p-[1.6rem] text-left ${
              isSupport
                ? 'bg-brand-light-black text-brand-white'
                : 'bg-brand-primary text-brand-white'
            }`}
          >
            <p
              className="message-content max-w-[80vw] whitespace-pre-line text-[1.3rem]"
              dangerouslySetInnerHTML={{
                __html: formatMessage(message.content).html,
              }}
            ></p>
          </div>

          {!!timestamp && (
            <p className="mr-4 self-end text-[1.3rem] text-brand-gray">
              {formatTimestamp(timestamp)}
            </p>
          )}
        </div>
      ) : (
        <MessageImage
          message={message}
          img={message.thumbnail ?? message.content}
          fallbackImg={message.download_url}
          onClick={onImageClick}
          isInbound
        />
      )}
    </div>
  </div>
);

function MessageAuto({ message }: { message: ChatMessageDocument }) {
  return (
    <div className="my-12 flex w-full items-center justify-center">
      <p
        className="message-content line-clamp-2 max-w-[80vw] truncate whitespace-pre-line text-center text-[1.3rem] text-zinc-500"
        // the auto messages will have 2 lines max.
        dangerouslySetInnerHTML={{
          __html: formatMessage(message.content).html,
        }}
      ></p>
    </div>
  );
}

export default function MessageBubble({
  chat,
  chatMessage,
  fallbackImg,
  onImageClick,
}: MessageBubbleProps) {
  const { userDoc } = useAuth();
  const { showSuccessToast } = useToastContext();

  const { data: messageUser } = useQuery({
    queryKey: ['publicUser', chatMessage.uid],
    queryFn: () => getPublicUserDoc({ uid: chatMessage.uid }),
    enabled: !!chatMessage.uid,
  });
  const [menuOpen, setMenuOpen] = useState(false);

  if (!userDoc) return null;

  const onRightClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (userDoc?.roles?.support) {
      ev.preventDefault();
      ev.stopPropagation();
      setMenuOpen(true);
    }
  };

  const isOutbound = chatMessage.uid === userDoc?.uid;
  const isAuto = chatMessage.is_auto;

  return (
    <div onContextMenu={onRightClick} className="grid">
      {isAuto ? (
        <MessageAuto message={chatMessage} />
      ) : (
        <>
          {isOutbound ? (
            <MessageOutbound
              message={chatMessage}
              timestamp={chatMessage.created_at ?? 0}
              fallbackImg={fallbackImg}
              user={messageUser}
              isSupport={userDoc.roles?.admin}
              onImageClick={onImageClick}
            />
          ) : (
            <MessageInbound
              message={chatMessage}
              timestamp={chatMessage.created_at ?? 0}
              isSupport={chatMessage.uid === process.env.NEXT_PUBLIC_SUPPORT_ID}
              user={messageUser}
              fallbackImg={fallbackImg}
              onImageClick={onImageClick}
            />
          )}
        </>
      )}

      {menuOpen && (
        <Popover className={isOutbound ? 'text-right' : ''}>
          <Popover.Panel static>
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Delete message"
              noXPadding
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this message?'
                  )
                ) {
                  deleteMessage(chat.id!, chatMessage);
                }
              }}
            />
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Copy message"
              noXPadding
              onClick={() => {
                navigator.clipboard.writeText(chatMessage.content);
                showSuccessToast(
                  'Message copied to clipboard',
                  'bottom-center'
                );
                setMenuOpen(false);
              }}
            />
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Cancel"
              noXPadding
              onClick={() => setMenuOpen(false)}
            />
          </Popover.Panel>
        </Popover>
      )}
    </div>
  );
}
