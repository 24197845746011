const ProductCardSkeleton = () => (
  <div className="flex max-w-[34.3rem] flex-col gap-[1.2rem] sm:max-w-[21.1rem] ">
    <div className="h-[32rem] animate-pulse rounded-[1.6rem] bg-brand-light-gray sm:aspect-square sm:max-h-[21.2rem]"></div>
    <div className="flex h-[7.7rem] flex-col gap-[.4rem]">
      <div className="h-[3rem] w-11/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
      <div className="h-[3rem] w-8/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
    </div>
  </div>
);

export const ListProductCardSkeleton = () => (
  <div className="flex min-h-[48.3rem] flex-col gap-[1.2rem] sm:min-h-[25rem] sm:flex-row sm:gap-[2.4rem]">
    {/* square */}
    <div className="h-[32rem] animate-pulse rounded-[1.6rem] bg-brand-light-gray sm:aspect-square sm:h-[25rem]" />
    {/* text */}
    <div className="flex w-full flex-col gap-[.4rem]">
      {/* title */}
      <div className="h-[6rem] w-full animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
      {/* description */}
      <div className="mt-[.4rem] h-[6rem] w-9/12 animate-pulse rounded-[1.6rem] bg-brand-light-gray" />
    </div>
  </div>
);
export default ProductCardSkeleton;
