import { BQFilterInput } from '@util/firestore/recommendations';
import useDebounce from '@util/hooks/useDebounce';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type SearchContextState = {
  searchTerm: string | undefined;
  setSearchTerm: (term: string | undefined) => void;
  debouncedSearchTerm: string | undefined;
  showSearchPreview: boolean;
  setShowSearchPreview: (val: boolean) => void;
  showMobileSearchbar: boolean;
  setShowMobileSearchbar: (val: boolean) => void;
  filters: BQFilterInput | null;
  setFilters: (filters: BQFilterInput | null) => void;
};
const SearchContext = createContext<SearchContextState | undefined>(undefined);

interface SearchProviderProps {
  children: ReactNode;
}
export const SearchProvider: FC<SearchProviderProps> = ({
  children,
}: SearchProviderProps) => {
  const [showSearchPreview, setShowSearchPreview] = useState(false);
  const [showMobileSearchbar, setShowMobileSearchbar] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filters, setFilters] = useState<BQFilterInput | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchTerm]);

  const value: SearchContextState = {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    showSearchPreview,
    setShowSearchPreview,
    showMobileSearchbar,
    setShowMobileSearchbar,
    filters,
    setFilters,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export function useSearch() {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
}
